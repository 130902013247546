import React, { useState } from 'react';
import NavbarList from '../navbarlist';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function SubNavbar() {

  const [isOpen, setIsOpen] = useState(false);
  const [isNavAboutOpen, setIsNavAboutOpen] = useState(false);
  const [isNavBusinessOpen, setIsNavBusinessOpen] = useState(false);
  const [isNavOfferOpen, setIsNavOfferOpen] = useState(false);
  const [isOurTeamOpen, setIsOurTeamOpen] = useState(false);
  const [isNavAchieveOpen, setIsNavAchieveOpen] = useState(false);
  const [isNavNewsOpen, setIsNavNewsOpen] = useState(false);
  const [isContactUsOpen, setIsContactUsOpen] = useState(false);

  const openNav = () => {
    setIsOpen(true);


  };

  const closeNav = () => {
    setIsOpen(false);
    setIsNavAboutOpen(false);
    setIsNavBusinessOpen(false);
    setIsNavOfferOpen(false);
    setIsOurTeamOpen(false);
    setIsNavAchieveOpen(false);
    setIsNavNewsOpen(false);
    setIsContactUsOpen(false);
  };


  // lable list start
  const toggleNavAbout = () => {

    if (!isNavAboutOpen) {
      setTimeout(() => {
        setIsNavAboutOpen(true);
      }, 500);
    } else {
      setTimeout(() => {
        setIsNavAboutOpen(false);
      }, 500)
    }
    setIsNavBusinessOpen(false);
    setIsNavOfferOpen(false);
    setIsOurTeamOpen(false);
    setIsNavAchieveOpen(false);
    setIsNavNewsOpen(false);
    setIsContactUsOpen(false);
  }


  const toggleNavBusiness = () => {
    if (!isNavBusinessOpen) {
      setTimeout(() => {
        setIsNavBusinessOpen(true);
      }, 500);
    } else {
      setTimeout(() => {

        setIsNavBusinessOpen(false);
      }, 500);

    }
    setIsNavAboutOpen(false);
    setIsNavOfferOpen(false);
    setIsOurTeamOpen(false);
    setIsNavAchieveOpen(false);
    setIsNavNewsOpen(false);
    setIsContactUsOpen(false);
  };

  const toggleNavOffer = () => {
    if (!isNavOfferOpen) {
      setTimeout(() => {
        setIsNavOfferOpen(true);
      }, 500);
    } else {
      setTimeout(() => {

        setIsNavOfferOpen(false);
      }, 500);

    }
    setIsNavBusinessOpen(false);
    setIsNavAboutOpen(false);
    setIsOurTeamOpen(false);
    setIsNavAchieveOpen(false);
    setIsNavNewsOpen(false);
    setIsContactUsOpen(false);
  };

  const toggleOurTeam = () => {
    if (!isOurTeamOpen) {
      setTimeout(() => {
        setIsOurTeamOpen(true);
      }, 500);
    } else {
      setTimeout(() => {

        setIsOurTeamOpen(false);
      }, 500);

    }
    setIsNavBusinessOpen(false);
    setIsNavOfferOpen(false);
    setIsNavAboutOpen(false);
    setIsNavAchieveOpen(false);
    setIsNavNewsOpen(false);
    setIsContactUsOpen(false);
  };

  const toggleNavAchieve = () => {
    if (!isNavAchieveOpen) {
      setTimeout(() => {
        setIsNavAchieveOpen(true);
      }, 500);
    } else {
      setTimeout(() => {

        setIsNavAchieveOpen(false);
      }, 500);


    }
    setIsNavBusinessOpen(false);
    setIsNavOfferOpen(false);
    setIsNavAboutOpen(false);
    setIsNavNewsOpen(false);
    setIsOurTeamOpen(false);
    setIsContactUsOpen(false);
  };

  const toggleNavNews = () => {
    if (!isNavNewsOpen) {
      setTimeout(() => {
        setIsNavNewsOpen(true);
      }, 500);
    } else {
      setTimeout(() => {

        setIsNavNewsOpen(false);
      }, 500);

    }
    setIsNavAboutOpen(false);
    setIsNavBusinessOpen(false);
    setIsNavOfferOpen(false);
    setIsOurTeamOpen(false);
    setIsNavAchieveOpen(false);
    setIsContactUsOpen(false);

  };

  const toggleContactUs = () => {
    if (!isContactUsOpen) {
      setTimeout(() => {
        setIsContactUsOpen(true);
      }, 500);
    } else {
      setTimeout(() => {

        setIsContactUsOpen(false);
      }, 500);

    }
    setIsNavAboutOpen(false);
    setIsNavBusinessOpen(false);
    setIsNavOfferOpen(false);
    setIsOurTeamOpen(false);
    setIsNavAchieveOpen(false);
    setIsNavNewsOpen(false);
  };


  const { i18n } = useTranslation();

  function changeLanguage(e) {
    console.log(e.target.value);
    i18n.changeLanguage(e.target.value);
  }
  // const handleScrollToTop = () => {
  //     window.scrollTo({ top: 0, behavior: 'smooth' });
  // };
  const goBack = () => {
    window.history.go(-1);
  };

  return (
    <>
      <div className="">
        <NavbarList
          isOpen={isOpen} closeNav={closeNav} toggleNavAbout={toggleNavAbout} toggleNavBusiness={toggleNavBusiness} toggleNavOffer={toggleNavOffer} toggleOurTeam={toggleOurTeam} toggleNavAchieve={toggleNavAchieve} toggleNavNews={toggleNavNews} toggleContactUs={toggleContactUs} isNavAboutOpen={isNavAboutOpen} isNavBusinessOpen={isNavBusinessOpen} isNavOfferOpen={isNavOfferOpen} isOurTeamOpen={isOurTeamOpen} isNavAchieveOpen={isNavAchieveOpen} isNavNewsOpen={isNavNewsOpen} isContactUsOpen={isContactUsOpen} setIsOpen={setIsOpen}
        />
        <div className="xl:pt-10 pt-5">
          <div className="flex justify-between ">
            <div className="">
              <Link to='/'>
                 <span class="material-symbols-outlined text-[#F1F1F1]  !text-[37px] xl:!text-[42px] cursor-pointer">
                home
              </span>
              </Link>
            </div>
            <div className="hidden">
              <img src="./logo.png" alt="" className="block md:hidden mt-10 md:w-[150px] w-[120px] " />
            </div>
            <div className='relative cursor-pointer  bg-white rounded-full grid content-center px-1 py-3 h-[35px] xl:h-[40px]' onClick={openNav}>
            <span class="material-symbols-outlined text-[#E5362E] !text-[30px] xl:!text-[30px] cursor-pointer">
              menu
            </span>
            </div>
          </div>
          {/* start */}
        </div>
      </div>
    </>
  )
}
