import React, { useEffect, useState } from "react";
import SubNavbar from "../subnavbar";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

export default function News() {
  const { t } = useTranslation();

  const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD; 
    // news
    const [newsSections, setNewsSections] = useState([]);


    useEffect(() => {
        axios
            .get(`${baseUrl}/get_latest_chronicles.php`, {
                headers: {
                    "Accept-Language": t('lang'), // Set the accepted language in the headers
                },
            })
            .then((response) => {
                console.log("API response data:", response.data);
                const sections = response.data; // Assuming the API response is the array of sections
                setNewsSections(sections); // Set the complete sections array


            })
            .catch((error) => {
                console.error("Error fetching offers:", error);
            });
    }, []);

    //


  return (
    <>
      <div className="relative bg-[#25292F] pb-10">
        <div className=" xl:px-[120px] text-justify px-6 relative min-h-screen border-transparent w-full text-black items-center justify-center">
          <div className="relative">
            <SubNavbar />
          </div>
          <div className="grid grid-cols-1  gap-4 pt-[30px]">
            <div className="">
              <p
                className="text-[16px] xl:text-[24px] lg:text-[24px]  font-semibold text-[#E8372C] tracking-wider"
                data-aos="zoom-in-right"
                data-aos-duration="500"
              >
                {t("news")}
              </p>
              <p
                className="text-[#F1F1F1] font-extralight text-[16px] lg:text-[20px] mt-[20px] leading-[35px]"
                data-aos="zoom-in-right"
                data-aos-duration="500"
              >
                We are committed to assisting our mdnsjdbhjsd clients and developing their
                security strategy with the objective of the best compromise
                between risks, authorities’ requirements, budget, corporate
                policies, environmental conditions and operational constraints.
              </p>
              <div className="grid grid-cols-2 mt-10 "></div>
            </div>
          </div>
          


<div className="hidden lg:grid grid-cols-2 lg:grid-cols-4 gap-2 justify-between -mt-5 lg:mt-0 mx-5 md:mx-10 lg:mx-0">

{newsSections.map((imageUrl, index) => (

  <Link to={`/newscard?title=${imageUrl.title}`}>
    <div key={index} className="flex justify-between">
      <div className="w-[275px] group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
        <div className="w-[275px]">
          <img
            className="h-full w-full object-cover transition-transform duration-500 group-hover:scale-110"
            src={imageUrl.image}
            alt=""
          />
        </div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
        <div className="absolute inset-0 flex translate-y-[90%] flex-col ml-3 mt-2 transition-all duration-500 group-hover:translate-y-0">
          <p className="mb-3 text-lg text-[#F1F1F1] opacity-0 transition-opacity duration-300 group-hover:opacity-100">
            {imageUrl.title}                                                </p>
          <button className="w-[100px] rounded-full bg-[#E5362E] py-2 px-3.5 font-com text-sm capitalize text-[#F1F1F1] shadow ">
            {t('readMore')}
          </button>
        </div>
      </div>
    </div>
  </Link>
))}

</div>

{/* mobile  */}
<div className="lg:hidden grid md:grid-cols-2 justify-items-center">

{newsSections.map((imageUrl, index) => (

  <Link to={`/newscard?title=${imageUrl.title}`}>
    <div key={index} className="mt-5">
      <div className="w-[275px] group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
        <div className="w-[275px]">
          <img
            className="h-full w-full object-cover transition-transform duration-500 group-hover:scale-110"
            src={imageUrl.image}
            alt=""
          />
        </div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
        <div className="absolute inset-0 flex translate-y-[90%] flex-col ml-3 mt-2 transition-all duration-500 group-hover:translate-y-0">
          <p className="mb-3 text-lg text-[#F1F1F1] opacity-0 transition-opacity duration-300 group-hover:opacity-100">
            {imageUrl.title}                                                </p>
          <button className="w-[100px] rounded-full bg-[#E5362E] py-2 px-3.5 font-com text-sm capitalize text-[#F1F1F1] shadow ">
            {t('readMore')}
          </button>
        </div>
      </div>
    </div>
  </Link>
))}

</div>

          <Link to="/newsmore">
            <div className="w-full justify-center content-center pl-5 hidden lg:flex">
              <motion.button
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                className="z-20 box text-[#F1F1F1]  bg-[#E8372C] md:text-[24px] text-[16px] md:px-4 px-3  md:py-2 md:mt-[20px] mt-2 py-2 rounded-[10px] tracking-wider"
              >
                {t("more")}
              </motion.button>
            </div>
          </Link>
          <Link to="/newsmore">
            <div className="w-full justify-center content-center pl-5 lg:hidden flex">
              <motion.button
                whileHover={{ scale: 1.1 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
                className="z-20 box text-[#F1F1F1]  bg-[#E8372C] md:text-[24px] text-[16px] md:px-4 px-3  md:py-2 md:mt-[20px] mt-2 py-2 rounded-[10px] tracking-wider"
              >
                {t("more")}
              </motion.button>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}