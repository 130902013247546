import React, { useEffect, useState } from "react";
import SubNavbar from "../subnavbar";
import { useTranslation } from "react-i18next";
import axios from "axios";

export default function ContactUs() {
  const { t } = useTranslation();
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;


// hiring and location
  const [contactSections, setContactSections] = useState([]);
  const [hiringSections, setHiringSections] = useState([]); 

  useEffect(() => {
    axios
      .get(`${baseUrl}/get_location.php`, {
        headers: {
          "Accept-Language": t("lang"),
        },
      })
      .then((contactResponse) => {
        console.log("API response data (contact):", contactResponse.data);
        const contactSectionsData = contactResponse.data;
        setContactSections(contactSectionsData);

        axios
          .get(`${baseUrl}/get_hiring.php`, {
            headers: {
              "Accept-Language": t("lang"),
            },
          })
          .then((hiringResponse) => {
            console.log("API response data (hiring):", hiringResponse.data);
            const hiringSectionsData = hiringResponse.data;
            setHiringSections(hiringSectionsData);

            //   setIsLoading(false);
          })
          .catch((hiringError) => {
            console.error("Error fetching hiring data:", hiringError);
          });
      })
      .catch((contactError) => {
        console.error("Error fetching contact data:", contactError);
      });
  }, []);

  
  
// new form post 

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    contactNumber: '',
    subject: '',
    message: '',
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const headers = {
        "Accept-Language": t("lang"),
      };

      const formDataObject = new FormData();
      formDataObject.append("fullName", formData.fullName);
      formDataObject.append("email", formData.email);
      formDataObject.append("contactNumber", formData.contactNumber);
      formDataObject.append("subject", formData.subject);
      formDataObject.append("message", formData.message);

      const response = await axios.post(
        `${baseUrl}/addContactUs.php`,
        formDataObject,
        { headers }
      );  

      if (response.data['isSuccess']) {
        setIsFormSubmitted(true);
        console.log("Form submission successful");
        setFormData({
          fullName: "",
          email: "",
          contactNumber: "",
          subject: "",
          message: "",
        })
      } else {
        console.log("Form submission error");
      }
    } catch (error) {
      console.error("Form submission error:", error);

    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

// dropdown
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);

  const toggleDropdown = (index) => {
    if (openDropdownIndex === index) {
      setOpenDropdownIndex(-1);
    } else {
      setOpenDropdownIndex1(-1);

      setOpenDropdownIndex(index);
    }
  };

  const [openDropdownIndex1, setOpenDropdownIndex1] = useState(-1);

  const toggleDropdown1 = (index) => {
    if (openDropdownIndex1 === index) {
      setOpenDropdownIndex1(-1);
    } else {
      setOpenDropdownIndex(-1);

      setOpenDropdownIndex1(index);
    }
  };

  return (
    <>
      <div className="h-auto pb-10 lg:min-h-screen w-full bg-gradient-to-r from-[#28313B] to-[#202020] text-black relative bg-cover  overflow-y-hidden  xl:px-[120px]">
        <div className="relative mx-5 xl:mx-0">
          <SubNavbar />
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 pt-[30px] xl:px-0 px-[20px]">
          <div className="mr-5 hidden lg:block ">
            <h2 className="text-[16px] xl:text-[24px] lg:text-[24px]  font-semibold text-[#E8372C] tracking-wider">
              {t("reachUs")}

            </h2>
            {contactSections.map((section, index) => (
              <div className={`relative w-full`} key={index}>
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                  onClick={() => toggleDropdown(index)}
                />
                <div className=" h-12 w-full flex items-center">
                  <p className="text-[#F1F1F1] md:mb-2 font-semibold lg:text-[24px] text-[16px] md:text-[20px]">
                    {section.country}
                  </p>
                </div>
                <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3">
                  {openDropdownIndex === index ? (
                    <span
                      className={`material-icons material-symbols-outlined ${
                        openDropdownIndex === index
                          ? "icon-expand-less"
                          : "icon-expand-more"
                      }`}
                    >
                      expand_less
                    </span>
                  ) : (
                    <span
                      className={`material-icons material-symbols-outlined ${
                        openDropdownIndex === index
                          ? "icon-expand-less"
                          : "icon-expand-more"
                      }`}
                    >
                      expand_more
                    </span>
                  )}
                </div>

                <div
                  className={`overflow-hidden transition-all duration-500 max-h-0 ${
                    openDropdownIndex === index ? "max-h-60" : ""
                  }`}
                >
                  {/* <p className="leading-5 md:leading-7 font-bold text-[#E8372C]">
                        <span className="leading-5 md:leading-7 font-medium  text-white w-[20px] text-justify">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Mauris sagittis ligula nec lacinia aliquet.
                          Aliquam eget vulputate magna. Integer tempus fringilla
                          ante, quis molestie augue fermentum ut.
                        </span>
                      </p>{" "} */}

                  <div className="w-full flex">
                    <div className="text-[#f1f1f1f1] lg:mt-4 ">
                      <div className="text-[16px] lg:text-[18px] w-[220px] md:w-[230px]">
                        <p className="leading-5 md:leading-7 font-bold text-[#E8372C]">
                          Address:
                          <span className="ml-2 leading-5 md:leading-7 font-medium text-white w-[20px]">
                            {section.address}
                          </span>
                        </p>{" "}
                        <p className=" leading-5 md:leading-7 font-bold text-[#E8372C]">
                          {" "}
                          E-mail:{" "}
                          <span className="leading-5 md:leading-7 font-medium text-white">
                            {section.email}
                          </span>{" "}
                        </p>
                        <p className="leading-5 md:leading-7 font-bold text-[#E8372C]">
                          {" "}
                          Tel:{" "}
                          <span className="leading-5 md:leading-7 font-medium text-white">
                            {" "}
                            {section.phone}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="relative ml-10 w-[40%] aspect-[16/9]">
                      <iframe
                        title={`map-${section.country}`}
                        src={section.location}
                        className="absolute top-0 left-0 w-full h-full rounded-2xl"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* career hiring */}

            <h2 className="xl:mt-20 lg:mt-10 text-[16px] xl:text-[24px] lg:text-[24px]  font-semibold text-[#E8372C] tracking-wider">
              {t("hire")}
            </h2>
            {hiringSections.map((section, index) => (
              <div className={`relative w-full`} key={index}>
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                  onClick={() => toggleDropdown1(index)}
                />
                <div className=" h-12 w-full flex items-center">
                  <p className="text-[#F1F1F1] md:mb-2 font-semibold lg:text-[24px] text-[16px] md:text-[20px]">
                    {section.title}
                  </p>
                </div>
                <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3">
                  {openDropdownIndex1 === index ? (
                    <span
                      className={`material-icons material-symbols-outlined ${
                        openDropdownIndex1 === index
                          ? "icon-expand-more"
                          : "icon-expand-less"
                      }`}
                    >
                      expand_less
                    </span>
                  ) : (
                    <span
                      className={`material-icons material-symbols-outlined ${
                        openDropdownIndex1 === index
                          ? "icon-expand-more"
                          : "icon-expand-less"
                      }`}
                    >
                      expand_more
                    </span>
                  )}
                </div>

                <div
                  className={`overflow-hidden transition-all duration-500 max-h-0 ${
                    openDropdownIndex1 === index ? "max-h-60" : ""
                  }`}
                >
                  <p className="leading-5 md:leading-7 font-bold text-[#E8372C]">
                    <span className="leading-5 md:leading-7 font-medium  text-white w-[20px] text-justify" dangerouslySetInnerHTML={{
                                __html: section.description
                              }} >
                      {/* {section.description} */}
                    </span>
                  </p>{" "}
                  <div className="flex ">
                    <a
                      href={section.pdfUrl} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                    >
                      <button className="leading-5 mt-3 md:leading-7 font-bold cursor-pointer w-[100px] rounded-full bg-[#E5362E] py-2 px-3.5 font-co4m text-sm capitalize text-[#F1F1F1] shadow">
                        Download
                      </button>
                    </a>
                  </div>
                  
                  <a href="https://mail.google.com" target="_blank">
                    {" "}
                    <p className="mt-3 leading-5 md:leading-7 font-bold text-[#E8372C] cursor-pointer">
                      {" "}
                      E-mail:{" "}
                      <span className="leading-5 md:leading-7 font-medium text-white cursor-pointer">
                        {section.email}{" "}
                      </span>{" "}
                    </p>{" "}
                  </a>{" "}
                </div>
              </div>
            ))}
          </div>

          {/* mobile */}

          <div className="block lg:hidden">
            <h2 className="mt-5 text-[16px] xl:text-[24px] lg:text-[24px]  font-semibold text-[#E8372C] tracking-wider">
              {t("reachUs")}
            </h2>
            {contactSections.map((section, index) => (
              <div className={`relative w-full`} key={index}>
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                  onClick={() => toggleDropdown(index)}
                />
                <div className=" h-12 w-full flex items-center">
                  <p className="text-[#F1F1F1] md:mb-2 font-semibold lg:text-[30px] text-[16px] md:text-[20px]">
                    {section.country}{" "}
                  </p>
                </div>
                <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3">
                  {openDropdownIndex1 === index ? (
                    <span
                      className={`material-icons material-symbols-outlined ${
                        openDropdownIndex1 === index
                          ? "icon-expand-more"
                          : "icon-expand-less"
                      }`}
                    >
                      expand_less
                    </span>
                  ) : (
                    <span
                      className={`material-icons material-symbols-outlined ${
                        openDropdownIndex1 === index
                          ? "icon-expand-more"
                          : "icon-expand-less"
                      }`}
                    >
                      expand_more
                    </span>
                  )}
                </div>
                <div
                  className={`overflow-hidden transition-all duration-500 max-h-0 ${
                    openDropdownIndex === index ? "max-h-60" : ""
                  }`}
                >
                  {" "}
                  <div className="w-full grid grid-cols-1 md:grid-cols-2">
                    <div className="text-[#f1f1f1f1] lg:mt-4 ">
                      <div className="text-[16px] lg:text-[18px] lg:w-[250px] xl:w-[300px] w-[200px] ">
                        <p className="leading-5 md:leading-7 font-bold text-[#E8372C] ">
                          Address:
                          <span className="ml-1 leading-5 md:leading-7 font-medium text-white w-[20px]">
                            {section.address}
                          </span>
                        </p>{" "}
                        <p className="mt-1 leading-5 md:leading-7 font-bold text-[#E8372C]">
                          {" "}
                          E-mail:{" "}
                          <span className="ml-1 leading-5 md:leading-7 font-medium text-white">
                            {section.email}
                          </span>{" "}
                        </p>
                        <p className="mt-1 leading-5 md:leading-7 font-bold text-[#E8372C]">
                          {" "}
                          Tel:{" "}
                          <span className="ml-1 leading-5 md:leading-7 font-medium text-white">
                            {" "}
                            {section.phone}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <iframe
                        title={`map-${section.country}`}
                        src={section.location}
                        className="mt-1 xl:w-[230px] xl:h-[210px] lg:w-[155px] lg:h-[200px] md:w-[260px] w-[150px] h-[120px] ml-2 rounded-xl"
                      ></iframe>
                      {/* <img src='/assets/map1.png' className=' md:w-[260px] w-[140px] h-[120px] ml-2 p-2'></img> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className=" lg:hidden">
            {/* career hirring */}
            <h2 className="mt-5 text-[16px] xl:text-[24px] lg:text-[24px]  font-semibold text-[#E8372C] tracking-wider">
              {t("hire")}
            </h2>
            {hiringSections.map((section, index) => (
              <div className={`relative w-full`} key={index}>
                <input
                  type="checkbox"
                  className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                  onClick={() => toggleDropdown1(index)}
                />
                <div className=" h-12 w-full flex items-center">
                  <p className="text-[#F1F1F1] font-semibold md:mb-2  lg:text-[24px] text-[16px] md:text-[20px]">
                    {section.title}
                  </p>
                </div>
                <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3">
                  {openDropdownIndex1 === index ? (
                    <span
                      className={`material-icons material-symbols-outlined ${
                        openDropdownIndex1 === index
                          ? "icon-expand-more"
                          : "icon-expand-less"
                      }`}
                    >
                      expand_less
                    </span>
                  ) : (
                    <span
                      className={`material-icons material-symbols-outlined ${
                        openDropdownIndex1 === index
                          ? "icon-expand-less"
                          : "icon-expand-more"
                      }`}
                    >
                      expand_more
                    </span>
                  )}
                </div>
                <div
                  className={`overflow-hidden transition-all duration-500 max-h-0 ${
                    openDropdownIndex1 === index ? "max-h-60" : ""
                  }`}
                >
                  <p className="leading-5 md:leading-7 font-bold text-[#E8372C]">
                    <span className="leading-5  md:leading-7 font-medium  text-white w-[20px] text-[15px] text-justify" dangerouslySetInnerHTML={{
                                __html: section.description
                              }} >
                      {/* {section.description} */}
                    </span>
                  </p>{" "}
                  <div className="flex items-center">
                    <a
                      href={section.pdfUrl} // Replace with the document URL
                      target="_blank" // Open in a new tab
                      rel="noopener noreferrer" // Recommended for security
                    >
                      <button className="leading-5 mt-3 md:leading-7 font-bold cursor-pointer w-[100px] rounded-full bg-[#E5362E] py-2 px-3.5 font-co4m text-sm capitalize text-[#F1F1F1] shadow">
                        Download
                      </button>
                    </a>
                  </div>
                  
                  <a href="https://mail.google.com" target="_blank">
                    {" "}
                    <p className="mt-3 leading-5 md:leading-7 font-bold text-[#E8372C] cursor-pointer">
                      {" "}
                      E-mail:{" "}
                      <span className="leading-5 md:leading-7 font-medium text-white cursor-pointer">
                        {section.email}{" "}
                      </span>{" "}
                    </p>
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className=" flex justify-center">
            <div className="md:ml-10">
              <h2 className="text-[16px] xl:text-[24px] lg:text-[24px] xl:-mt-5 mb-5 font-semibold text-[#E8372C] tracking-widest">
                {t("contact")}
              </h2>
              <form
              
                className="lg:mt-5 mt-2 xl:w-[536px] md:w-[380px] w-[280px]"
                onSubmit={handleSubmit()}
                method="post" encType="multipart/form-data"
              >
                <div className="inputbox">
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    required
                  />
                  <span>{t("fullName")}</span>
                  <i></i>
                </div>
                {/* mail  */}
                <div className="inputbox">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <span>{t("email")}</span>
                  <i></i>
                </div>
                <div className="inputbox">
                  <input
                    type="tel"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleInputChange}
                    required
                  />
                  <span>{t("contactNum")}</span>
                  <i></i>
                </div>
                {/* subject  */}
                <div className="inputbox">
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                  />
                  <span>{t("subject")}</span>
                  <i></i>
                </div>

                <textarea
                  className="w-full lg:h-[150px] h-[70px] md:h-[110px] xl:h-[190px]  p-2  text-[14px] rounded-md mb-2 bg-transparent border border-[#E8372C]"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder={t("message")}
                  required
                ></textarea>

                
                <button
                  type="submit"
                  className="bg-[#F23C25] text-[#F1F1F1] w-full h-[30px] lg:h-[52px] text-[14px] font-semibold px-2 rounded"
                >
                  {t("send")}
                </button>
              </form>
              {isFormSubmitted && (
                <p className="text-green-500 mt-2">
                  Form submitted successfully!
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="xl:px-[120px] px-[20px] bg-gradient-to-r from-[#28313B] to-[#202020] h-40 pt-10">
        <hr className="py-2 border-[#F23C25]" />
        <div className="lg:flex justify-between">
        <div className="flex justify-center">
            {/* <img src="./assets/facebook.png" alt="" className="w-[30px] md:w-[40px] lg:w-[50px]" /> */}
            {/* <img src="./assets/linkdin.png" alt="" className="w-[30px] md:w-[40px] lg:w-[50px] ml-5" /> */}
            <a
              href="https://www.linkedin.com/company/cronos-conseil/about/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src="./assets/linkdin.png"
                alt=""
                className="w-[30px] md:w-[40px] lg:w-[50px] ml-5"
              />
            </a>

            {/* <img src="./assets/insta.png" alt="" className="w-[30px] md:w-[40px] lg:w-[50px] ml-5" /> */}
          </div>
          <div className="py-3">
            <p className="text-[10px] text-center md:text-[12px] lg:text-[14px] text-[#f1f1f1] opacity-[60%]">
              © Cronos international- 2023. All rights reserved, Designed &
              Developed by Meerana Technologies LLP
            </p>
          </div>
        </div>
      </div>
    </>
  );
}