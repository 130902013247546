import React, {  useEffect, useState } from 'react'
import NavbarList from '../navbarlist';
import { useTranslation } from 'react-i18next';

export default function Navbar() {

    const [isOpen, setIsOpen] = useState(false);
    const [isNavAboutOpen, setIsNavAboutOpen] = useState(false);
    const [isNavBusinessOpen, setIsNavBusinessOpen] = useState(false);
    const [isNavOfferOpen, setIsNavOfferOpen] = useState(false);
    const [isOurTeamOpen, setIsOurTeamOpen] = useState(false);
    const [isNavAchieveOpen, setIsNavAchieveOpen] = useState(false);
    const [isNavNewsOpen, setIsNavNewsOpen] = useState(false);
    const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  
  
    const openNav = () => {
      setIsOpen(true);
  
  
    };
  
    const closeNav = () => {
      setIsOpen(false);
      setIsNavAboutOpen(false);
      setIsNavBusinessOpen(false);
      setIsNavOfferOpen(false);
      setIsOurTeamOpen(false);
      setIsNavAchieveOpen(false);
      setIsNavNewsOpen(false);
      setIsContactUsOpen(false);
    };
  
  
    // lable list start
    const toggleNavAbout = () => {
  
      // setIsNavAboutOpen((prevValue) => !prevValue);
      if (!isNavAboutOpen) {
        setTimeout(() => {
          setIsNavAboutOpen(true);
        }, 500);
      } else {
        setTimeout(() => {
          setIsNavAboutOpen(false);
        }, 500)
      }
      setIsNavBusinessOpen(false);
      setIsNavOfferOpen(false);
      setIsOurTeamOpen(false);
      setIsNavAchieveOpen(false);
      setIsNavNewsOpen(false);
      setIsContactUsOpen(false);
    }
  
  
    const toggleNavBusiness = () => {
      // setIsNavBusinessOpen((prevValue) => !prevValue);
      if (!isNavBusinessOpen) {
        setTimeout(() => {
          setIsNavBusinessOpen(true);
        }, 500);
      } else {
        setTimeout(() => {
  
          setIsNavBusinessOpen(false);
        }, 500);
  
      }
      setIsNavAboutOpen(false);
      setIsNavOfferOpen(false);
      setIsOurTeamOpen(false);
      setIsNavAchieveOpen(false);
      setIsNavNewsOpen(false);
      setIsContactUsOpen(false);
    };
  
    const toggleNavOffer = () => {
      // setIsOurOfferOpen((prevValue) => !prevValue);
      if (!isNavOfferOpen) {
        setTimeout(() => {
          setIsNavOfferOpen(true);
        }, 500);
      } else {
        setTimeout(() => {
  
          setIsNavOfferOpen(false);
        }, 500);
  
      }
      setIsNavBusinessOpen(false);
      setIsNavAboutOpen(false);
      setIsOurTeamOpen(false);
      setIsNavAchieveOpen(false);
      setIsNavNewsOpen(false);
      setIsContactUsOpen(false);
    };
  
    const toggleOurTeam = () => {
      // setIsOurTeamOpen((prevValue) => !prevValue);
      if (!isOurTeamOpen) {
        setTimeout(() => {
          setIsOurTeamOpen(true);
        }, 500);
      } else {
        setTimeout(() => {
  
          setIsOurTeamOpen(false);
        }, 500);
  
      }
      setIsNavBusinessOpen(false);
      setIsNavOfferOpen(false);
      setIsNavAboutOpen(false);
      setIsNavAchieveOpen(false);
      setIsNavNewsOpen(false);
      setIsContactUsOpen(false);
    };
  
    const toggleNavAchieve = () => {
      // setIsNavAchieveOpen((prevValue) => !prevValue);
      if (!isNavAchieveOpen) {
        setTimeout(() => {
          setIsNavAchieveOpen(true);
        }, 500);
      } else {
        setTimeout(() => {
  
          setIsNavAchieveOpen(false);
        }, 500);
  
  
      }
      setIsNavBusinessOpen(false);
      setIsNavOfferOpen(false);
      setIsNavAboutOpen(false);
      setIsNavNewsOpen(false);
      setIsOurTeamOpen(false);
      setIsContactUsOpen(false);
    };
  
    const toggleNavNews = () => {
      // setIsNavNewsOpen((prevValue) => !prevValue);
      if (!isNavNewsOpen) {
        setTimeout(() => {
          setIsNavNewsOpen(true);
        }, 500);
      } else {
        setTimeout(() => {
  
          setIsNavNewsOpen(false);
        }, 500);
  
      }
      setIsNavAboutOpen(false);
      setIsNavBusinessOpen(false);
      setIsNavOfferOpen(false);
      setIsOurTeamOpen(false);
      setIsNavAchieveOpen(false);
      setIsContactUsOpen(false);
  
    };
  
    const toggleContactUs = () => {
      // setIsContactUsOpen((prevValue) => !prevValue);
      if (!isContactUsOpen) {
        setTimeout(() => {
          setIsContactUsOpen(true);
        }, 500);
      } else {
        setTimeout(() => {
  
          setIsContactUsOpen(false);
        }, 500);
  
      }
      setIsNavAboutOpen(false);
      setIsNavBusinessOpen(false);
      setIsNavOfferOpen(false);
      setIsOurTeamOpen(false);
      setIsNavAchieveOpen(false);
      setIsNavNewsOpen(false);
    };
    
  

    const { i18n } = useTranslation();

 
    

    useEffect(() => {
      // Check if a language preference is stored in localStorage
      const storedLanguage = localStorage.getItem('preferredLanguage');
      if (storedLanguage) {
        i18n.changeLanguage(storedLanguage);
      }
    }, [i18n]);
    
    const changeLanguage = (language) => {
      i18n.changeLanguage(language);
      // Store the selected language preference in localStorage
      localStorage.setItem('preferredLanguage', language);
      window.location.reload();

    };


  return (
    <>
    <div>
       <div className="grid content-center h-20 lg:h-40 z-20 w-full absolute  animate__animated animate__fadeInDown animate__delay-1s" >
        <div className="flex justify-between mx-5 lg:mx-7 xl:mx-[120px] ">
          <div className=" ">
            <img src="/assets/logo.png" alt="" className="md:block hidden md:w-[150px] w-[120px] " />
            
          </div>
          <div className="block md:hidden z-10 text-[#F1F1F1] absolute left-2 mt-2 lg:mt-0">
          <button className="text-[14px] cursor-pointer " onClick={() => changeLanguage('fr')} value='fr'>F R &nbsp;|</button>
          <input type="checkbox" className='hidden peer' />
             
             <button className="text-[14px]  cursor-pointer " onClick={() => changeLanguage('en')} value='en'>&nbsp;E N</button>
          </div>
          <div className="grid justify-items-center w-full md:hidden">
            <img src="/assets/logo.png" alt="" className="block md:hidden ml-10 md:w-[150px] w-[120px] " />
          </div>
          <div className="text-[#F1F1F1] flex  lg:ml-60 ">
            <button className="text-[18px] mr-3 md:mr-2 md:block hidden cursor-pointer " onClick={() => changeLanguage('fr')} value='fr'>F R &nbsp;&nbsp;|</button>
             
            <button className="text-[18px] mr-3 md:mr-14 lg:mr-2 md:block hidden cursor-pointer " onClick={() => changeLanguage('en')} value='en'>&nbsp;&nbsp;E N</button>
          
              <div className="grid content-center">
              <div className='relative cursor-pointer mb-1 bg-white rounded-full grid content-center p-1' onClick={openNav}>
                <span class="material-symbols-outlined text-[#E5362E] !text-[30px] xl:!text-[42px] cursor-pointer">
                menu
                </span>
              </div>
              </div>
          </div>
        </div>
      </div>

      <NavbarList isOpen={isOpen}  closeNav={closeNav} toggleNavAbout={toggleNavAbout} toggleNavBusiness={toggleNavBusiness} toggleNavOffer={toggleNavOffer} toggleOurTeam={toggleOurTeam} toggleNavAchieve={toggleNavAchieve} toggleNavNews={toggleNavNews} toggleContactUs={toggleContactUs} isNavAboutOpen={isNavAboutOpen} isNavBusinessOpen={isNavBusinessOpen} isNavOfferOpen={isNavOfferOpen} isOurTeamOpen={isOurTeamOpen} isNavAchieveOpen={isNavAchieveOpen} isNavNewsOpen={isNavNewsOpen} isContactUsOpen={isContactUsOpen} setIsOpen={setIsOpen} />

      </div>
    </>
  )
}
