import React, { useEffect, useState } from 'react'
import SubNavbar from '../../subnavbar'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function TeamManager(props) {
  const { t, i18n } = useTranslation();
  const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const managerName = searchParams.get('name');
  console.log('Selected Manager Name:', managerName);

  const [managerData, setManagerData] = useState(null);
  useEffect(() => {
    if (managerName) {
      // Fetch data for the selected manager using their name
      fetch(`${baseUrl}/get_ourteams.php?name=${managerName}`, {
        headers: {
          'Accept-Language': t('lang'),
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log('API response data:', data);

          if (Array.isArray(data) && data.length > 0) {
            // Access the name property from the first object in the array
            setManagerData(data[0]);
          } else {
            console.error('No manager data found.');
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [managerName]);

  if (managerData === null) {
    return <>Loading...</>;
  }


  return (
    <>
      <div className=" mt-[ -200px] bg-gradient-to-r from-[#28313B] to-[#202020] overflow-y-auto ">
        <div className="relative z-20 h-screen w-full px-5 xl:px-[120px]">
          <SubNavbar />
          <div className="grid grid-cols-1 pb-5">
            <div className="flex space-x-12 mt-5 md:mt-10 ">
              <p className="text-[17px] font-bold text-[#F1F1F1] tracking-wider">
                {t('ourTeam')}
              </p>
            </div>
            <div className="block md:flex ">
              <div className="mt-5">
                <img
                  src={managerData.image}
                  alt=""
                  className="w-[218px] h-[212px]"
                />
              </div>

              <div className="w-full lg:pl-5">
                <div className="block  bg-[#E5362E] mt-5  w-full p-2 h-auto md:h-[80px]  text-[#F1F1F1]">
                  <h1>{managerData.name}</h1>
                  <p dangerouslySetInnerHTML={{ __html: managerData.description }}></p>
                </div>
                <div className="block text-[14px] lg:text-[18px] bg-[#E5362E] mt-5 w-full p-2   text-[#F1F1F1]">

                  <p>{managerData.expYears}</p>
                </div>
              </div>
            </div>

            <div className="block md:flex">
              <div className="">
                <h1 className="bg-[#E5362E] p-2 h-auto text-[#F1F1F1] text-center justify-normal mt-5">
                  {t('personalDetail')}
                </h1>

                <table className="mt-5 table-auto lg:w-[500px] w-[290px]">
                  <tbody className="text-[#F1F1F1] text-[14px] lg:text-[18px] text-start">
                    <tr className='ml-2'>
                      <td className='lg:w-[130px] w-[95px] py-1'>{t('nationality')}</td>
                      <td>{managerData.nationality}</td>
                    </tr>
                    <tr>
                      <td className='lg:w-[130px] py-1'>{t('disciplene')}</td>
                      <td>{managerData.discipline}</td>
                    </tr>
                    <tr>
                      <td className='lg:w-[130px] py-1'>{t('location')}</td>
                      <td>{managerData.location}</td>
                    </tr>
                    <tr>
                      <td className='lg:w-[130px] py-1'>{t('language')}</td>
                      <td>{managerData.languages}</td>
                    </tr>
                    <tr>
                      <td className="grid content-start lg:w-[130px]">{t('academics')}</td>
                      <td>{managerData.academics}</td>
                    </tr>
                    <tr>
                      <td className="grid content-start lg:w-[130px]">{t('skills')}</td>
                      <td dangerouslySetInnerHTML={{ __html: managerData.skills }}></td>

                      {/* <td className="text-justify">
                        <ul className="list-disc " style={{ listStyleType: 'none' }}>
                          {managerData.skills.replace(/<[^>]*>/g, '').split(',').map((skill, index) => (
                            <li key={index}>{skill.trim()}</li>
                          ))}
                        </ul>
                      </td> */}
                      



                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="lg:pl-10 w-full">
                <div className="block">
                  <div className="bg-[#E5362E]  p-2 h-full w-full text-[#F1F1F1] text-center justify-normal mt-5">
                    <h1>{t('professionalExp')}</h1>
                  </div>

                  <div className="text-[#F1F1F1] lg:text-[18px] lg:ml-10 ml-2 text-justify">
                    <ul className=" list-disc ml-[6px] lg:ml-5 space-y-3 text-[#F1F1F1] mt-2 lg:mt-5">
                      {managerData.experience.map((exp, index) => (
                        // Display each experience point as an li element
                        <li key={index}>{exp}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}
