import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function NavAchieve(props) {
  const { t } = useTranslation();
  const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD; 


  const [achieveSections, setAchieveSections] = useState([]);


  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`${baseUrl}/get_achievements.php`, {
        headers: {
          "Accept-Language": t('lang'), // Set the accepted language in the headers
        },
      })
      .then((response) => {
        console.log("API response data:", response.data); // Log the API response
        const sections = response.data; // Assuming the API response is the array of sections
        setAchieveSections(sections); // Set the complete sections array

      })
      .catch((error) => {
        console.error("Error fetching offers:", error);
      });
  }, []);

  const handleNavItemClick = () => {
    props.closeNav(); // Call the closeNav function passed as a prop
  };

  return (
    <>
      <div className="hidden md:flex xl:mx-32 leading-10 -mt-10 ">
        <div>
          {achieveSections.map((navachieve, index) => (
            <Link to={`/ourachievecard?title=${navachieve.title}`}>
              <h2
                key={index}
                className='text-[#F1F1F1] text-[20px]' onClick={handleNavItemClick}
              >
                {navachieve.title}
              </h2>   </Link>
          ))}
        </div>
      </div>

    </>
  )
}
