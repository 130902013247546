import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
export default function NavBusiness() {
  
 const { t } = useTranslation();

 const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD; 

 const [businessSections, setBusinessSections] = useState([]);



 useEffect(() => {
   // Fetch data from the API
   axios
     .get(`${baseUrl}/get_business.php`, {
       headers: {
         "Accept-Language": t('lang'), // Set the accepted language in the headers
       },
     })
     .then((response) => {
       console.log("API response data:", response.data); // Log the API response
       const sections = response.data; // Assuming the API response is the array of sections
       setBusinessSections(sections); // Set the complete sections array

       
     })
     .catch((error) => {
       console.error("Error fetching offers:", error);
     });
 }, []);
 const description = businessSections.length > 0 ? businessSections[0].description : "";


  return (
    <>
      <div className="hidden md:flex justify-center xl:mx-32 mx-[20px] -mt-10">

    <div>
    <p className="text-[#F1F1F1] text-[16px] xl:text-[18px] text-justify " dangerouslySetInnerHTML={{__html:description}}>
   </p>
        {/* <p className="text-[#F1F1F1] text-[20px] text-justify " >{description} </p> */}
      <Link to="/business" >  <button className=" border-2 border-white text-[#F1F1F1] text-[10px]  xl:text-[17px] mt-5 px-6 py-2 rounded-[8px] ">{t('readMore')}</button>
      </Link>
    </div>
  </div>
    </>
  )
}
