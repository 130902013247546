import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SubNavbar from "../../subnavbar";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function OurTeam() {
  const { t } = useTranslation();
  const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD;

  const goBack = () => {
    window.history.back();
  };

  const [teamData, setTeamData] = useState({});
  const [showDetails, setShowDetails] = useState({});

  useEffect(() => {
    axios
      .get(`${baseUrl}/get_ourteams.php`, {
        headers: {
          "Accept-Language": t("lang"),
        },
      })
      .then((response) => {
        console.log("API response data:", response.data);
        const apiData = response.data;
        const dynamicTeamData = {};

        const positions = [...new Set(apiData.map((member) => member.position))];

        positions.forEach((position) => {
          dynamicTeamData[position] = apiData.filter(
            (member) => member.position === position
          );
        });

        setTeamData(dynamicTeamData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [baseUrl, t]);

  const handleMouseEnter = (position, index) => {
    setShowDetails((prevDetails) => ({
      ...prevDetails,
      [position]: index,
    }));
  };

  const handleMouseLeave = (position) => {
    setShowDetails((prevDetails) => ({
      ...prevDetails,
      [position]: null,
    }));
  };
  return (
    <>
      <div className='relative w-full  h-full '>

        <div className="absolute top-0  w-full xl:px-[120px] pb-10 px-[20px] bg-gradient-to-r from-[#28313B] to-[#202020] pb-[120px]">
          <div className="">
            <SubNavbar />
          </div>
          <div className="h-auto my-16 border-l-2 border-white absolute"></div>
          <div className="block w-full">
            <div className=" mt-14">
              <div className="flex space-x-4 ">
                <p className="text-[16px] lg:text-[17px] font-bold  tracking-wider text-[#F23C25]">{t('ourTeam')}</p>
              </div>
              {Object.keys(teamData).map((positionKey) => (
                <div key={positionKey} className="mt-16">
                  <h1 className="xl:text-[20px] text-[17px] font-normal text-white tracking-wider">
                    {t(positionKey).toUpperCase()}
                  </h1>                
                    <div className="grid grid-cols-1 gap-1 md:grid-cols-3 xl:grid-cols-5 mt-5 w-full">
                    {teamData[positionKey].map((member, index) => (
                      <div key={index} className="relative group mb-5 mt-10 md:mt-0">
                        {/* <h2 className="text-[16px] font-bold text-white">{member.name}</h2> */}
                        <Link to={`/manager?name=${member.name}`}>
                          <LazyLoadImage
                            src={member.image}
                            alt={member.name}
                            onMouseLeave={() => handleMouseLeave(positionKey)}
                            onMouseEnter={() => handleMouseEnter(positionKey, index)}
                            loading="lazy"
                            // className="!max-w-[200px] h-[230px] !rounded-[10px] !object-fit"
                            className="w-[200px] h-[230px] md:w-[200px] md:h-[230px] rounded-md object-cover"

                          />

                        </Link>
                        {showDetails[positionKey] === index && (
                          <div className="absolute left-0 w-[550px] text-[#EF6722] bg-white p-1 border-t border-[#EF6722] rounded-[10px] mt-2">
                            <h2 className="text-[16px] font-bold">{member.name}</h2>
                            <p className="mt-1 text-[13px] font-medium" dangerouslySetInnerHTML={{ __html: member.description }}></p>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

          </div>
        </div>
      </div>
    </>
  )
}
