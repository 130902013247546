import './App.css';
import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import Home from './component/home';
import Herosection from './component/herosection';
import NavOffer from './component/navmenu/nav-offer';
import NavAbout from './component/navmenu/nav-about';
import NavBusiness from './component/navmenu/nav-business';
import NavAchieve from './component/navmenu/nav-achive';
import ContactUs from './component/contactus';
import CompanyOverview from './component/aboutus/companyoverview';
import DnaMission from './component/aboutus/dna-mission';
import Business from './component/business';
import SecurityRisk from './component/ouroffer/securityrisk';
import AchieveCard from './component/our-achieve-card';
import News from './component/news';
import NewsCard from './component/news/news-card';
import NewsMore from './component/news/news-more';
import OurTeam from './component/ourteam/team';
import TeamManager from './component/ourteam/team-manager';
import Gototop from './component/gototop';
import './i18n/config';
import OfferCard from './component/our-offer-card';
import smoothscroll from 'smoothscroll-polyfill';

// Apply polyfill
smoothscroll.polyfill();


function App() {
  return (
    <BrowserRouter>
    <Gototop/>
      <Routes>
        <Route path='/' Component={Home}/>  
        <Route path='/herosection' Component={Herosection}/>
        <Route path='/navabout' Component={NavAbout}/>
        <Route path='/navbusiness' Component={NavBusiness}/>
        <Route path='/navoffer' Component={NavOffer}/>
        <Route path='/navachieve' Component={NavAchieve}/>
        <Route path='/contactus' Component={ContactUs}/>
        <Route path='/companyoverview' Component={CompanyOverview}/>
        <Route path='/dna&mission' Component={DnaMission}/>
        <Route path='/business' Component={Business}/>
        <Route path='/securityrisk' Component={SecurityRisk}/>
        <Route path='/ouroffercard' Component={OfferCard}/>
        <Route path='/ourachievecard' Component={AchieveCard}/>
        <Route path='/news' Component={News}/>
        <Route path='/newscard' Component={NewsCard}/>
        <Route path='/newsmore' Component={NewsMore}/>
        <Route path='/ourteam' exact Component={OurTeam}/>
        <Route path="/manager" Component={TeamManager}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
