import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

export default function NavOffer(props) {
  const { t } = useTranslation();

  const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD;

  const [offerSections, setOfferSections] = useState([]);
  const [isNavOpen, setIsNavOpen] = useState(false);


  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`${baseUrl}/get_offers.php`, {
        headers: {
          "Accept-Language": t('lang'), // Set the accepted language in the headers
        },
      })
      .then((response) => {
        console.log("API response data:", response.data); // Log the API response
        const sections = response.data; // Assuming the API response is the array of sections
        setOfferSections(sections); // Set the complete sections array
      })
      .catch((error) => {
        console.error("Error fetching offers:", error);
      });
  }, []);


  // const handleNavItemClick = (index) => {
  //   const sectionRef = document.getElementById(`section-${index}`);
  //   if (sectionRef) {
  //     sectionRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
  //     props.closeNav();
  //   }
  // };


  const navigate = useNavigate();
  // const scrollToSection = (index) => {
  //   // navigate("/securityrisk");
  //   setTimeout(() => {
  //     const sectionId = `section-${index}`;
  //     const sectionElement = document.getElementById(sectionId);
  //     if (sectionElement) {
  //       sectionElement.scrollIntoView({ behavior: "smooth" });
  //       props.closeNav();
  //     }
  //   }, 100); // Delay for smoother scroll
  // }

  const scrollToSection = (index) => {
    const sectionId = `section-${index}`;
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      const yOffset = sectionElement.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({ top: yOffset, behavior: 'smooth' });
      props.closeNav();
    }
  };


  return (
    <>
      <div className="hidden md:flex justify-center xl:mx-32 leading-10 -mt-10 ">
        <div>
          {offerSections.map((navoffer, index) => (
            <div key={index} id={`section-${navoffer.title}`}>
              <Link
                to={`/securityrisk?title=${navoffer.title}`}
                onClick={() => scrollToSection(navoffer.title)}



              >
                <h2 className="text-[#F1F1F1] text-[20px]">{navoffer.title}</h2>
              </Link>
            </div>
          ))}
        </div>
    

      </div>
    </>
  )
}
