import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Animation from "../animation";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import axios from "axios";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";

const Herosection = React.forwardRef((props, ref) => {
  const contentRef = useRef(null);
  const [homeInfo, setHomeInfo] = useState({});
  


  const { t } = useTranslation();
  const language = t("lang");
  const [isLoading, setIsLoading] = useState(true);

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  console.log(baseUrl);
  const getHomepageInfo = () => {
    axios({
      url: `${baseUrl}/get_homepage.php`,
      method: "GET",
      headers: {
        "Accept-Language": t("lang"),
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // console.log(res.data);

        setHomeInfo(res.data[0]);


      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getHomepageInfo();
    AOS.init({
      duration: 800,
      once: false,
    });
  }, []);
  const [newsSections, setNewsSections] = useState([]);
  const [contactSections, setContactSections] = useState([]);
  const [hiringSections, setHiringSections] = useState([]); // Add state for hiring 
  const [mapLocation, setMapLocation] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch news data from the first API endpoint
        const newsResponse = await axios.get(
          `${baseUrl}/get_latest_chronicles.php`,
          {
            headers: {
              "Accept-Language": t("lang"), // Set the accepted language in the headers
            },
          }
        );
        console.log("API response data (news):", newsResponse.data);
        setNewsSections(newsResponse.data);

        // Fetch contact data from the second API endpoint
        const contactResponse = await axios.get(`${baseUrl}/get_location.php`, {
          headers: {
            "Accept-Language": t("lang"), // Set the accepted language in the headers
          },
        });
        console.log("API response data (contact):", contactResponse.data);
        setContactSections(contactResponse.data);

        // Fetch hiring data from a third API endpoint
        const hiringResponse = await axios.get(`${baseUrl}/get_hiring.php`, {
          headers: {
            "Accept-Language": t("lang"), // Set the accepted language in the headers
          },
        });
        console.log("API response data (hiring):", hiringResponse.data);
        setHiringSections(hiringResponse.data);

        const mapLocationResponse = await axios.get(`${baseUrl}/get_mapLocation.php`, {
          headers: {
            "Accept-Language": t("lang"), // Set the accepted language in the headers
          },
        });
        console.log("API response data (MapLocation):", mapLocationResponse.data);
        const result = mapLocationResponse.data
        setMapLocation(result);

        // Mark data loading as complete
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here
      }
    }

    fetchData();
  }, []);

  const cronoasdna = useRef(null);
  const third = useRef(null);
  const fourth = useRef(null);
  const contact = useRef(null);

  const handleScrollNext1 = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollNext2 = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollNext3 = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollNext4 = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const outerDivRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  useEffect(() => {
    const updateDivHeight = () => {
      // Measure the height of the outermost div and set it in the state
      if (outerDivRef.current) {
        setDivHeight(outerDivRef.current.offsetHeight);
      }
    };

    // Initial measurement
    updateDivHeight();

    // Add a resize event listener to update divHeight when the window size changes
    window.addEventListener("resize", updateDivHeight);

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("resize", updateDivHeight);
    };
  }, []);

  // new form post

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    contactNumber: "",
    subject: "",
    message: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const headers = {
        "Accept-Language": t("lang"),
      };

      const formDataObject = new FormData();
      formDataObject.append("fullName", formData.fullName);
      formDataObject.append("email", formData.email);
      formDataObject.append("contactNumber", formData.contactNumber);
      formDataObject.append("subject", formData.subject);
      formDataObject.append("message", formData.message);

      const response = await axios.post(
        `${baseUrl}/addContactUs.php`,
        formDataObject,
        { headers }
      );

      if (response.data['isSuccess']) {
        setIsFormSubmitted(true);
        console.log("Form submission successful");
        setFormData({
          fullName: "",
          email: "",
          contactNumber: "",
          subject: "",
          message: "",
        })
      } else {
        console.error("Form submission error");
      }
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);

  const toggleDropdown = (index) => {
    if (openDropdownIndex === index) {
      // Clicking on an already open dropdown should close it
      setOpenDropdownIndex(-1);
    } else {
      setOpenDropdownIndex1(-1);

      // Clicking on a closed dropdown should open it and close others
      setOpenDropdownIndex(index);
    }
  };

  const [openDropdownIndex1, setOpenDropdownIndex1] = useState(-1);

  const toggleDropdown1 = (index) => {
    if (openDropdownIndex1 === index) {
      // Clicking on an already open dropdown should close it
      setOpenDropdownIndex1(-1);
    } else {
      setOpenDropdownIndex(-1);

      // Clicking on a closed dropdown should open it and close others
      setOpenDropdownIndex1(index);
    }
  };

  // download documed or pdf
  function downloadDocument(documentUrl) {
    // Create a hidden anchor element
    const link = document.createElement("a");
    link.href = documentUrl;
    link.target = "_blank"; // Opens the link in a new tab/window
    link.download = "your_document.pdf"; // The suggested name for the downloaded file

    // Trigger a click event to start the download
    link.click();
  }

  // if (isLoading) {
  //     return <p>Loading...</p>;
  //   }
console.log("mapLocation",mapLocation[0]?.location)
  return (
    <>
      <div className="bg-gradient-to-r from-[#28313B] to-[#202020] ">
        <div ref={outerDivRef} className="overflow-hidden  !h-auto">
          <div className="hidden  lg:flex justify-center  absolute left-3  right-0 bottom-[51px] z-20 invisible lg:visible">
            <Animation divHeight={divHeight} className="" />
          </div>
          {/* //company page 2 */}
          <div className="relative " ref={contentRef}>
            <div
              ref={ref}
              className="relative h-full mt-5 lg:min-h-screen border-transparent w-full text-black items-center justify-center "
            >
              <div className="grid grid-cols-1 md:grid-cols-2 md:mt-10 gap-4 mt-0">
                {" "}
                <div className="xl:pl-[120px] pl-[20px]">
                  <div className="flex pt-5 lg:pt-0">
                 

                    <img
                    src={homeInfo.teamProfile}
                      alt=""
                      className="xl:w-[193px] xl:h-[198px] lg:w-[160px] lg:h-[170px] w-[120px]"
                      data-aos="zoom-in-right"
                    />
                    <h1
                      className="text-[16px] lg:text-[17px] xl:mt-[100px] ml-2 xl:text-[24px] font-bold text-[#E8372C] tracking-wider mt-2"
                      data-aos="zoom-in-right"
                    >
                      {t("florenceSpeech")}
                    </h1>
                  </div>
                  {homeInfo && (
                    <p
                      className="text-[#F1F1F1] text-[16px] xl:text-[18px] lg:mt-[10px] lg:leading-[20px] xl:leading-[25px] text-justify pr-2"
                      data-aos="zoom-in-right"
                      dangerouslySetInnerHTML={{ __html: homeInfo.speech }}
                    ></p>
                  )}
                </div>
                <div className="text-left ml-6 md:mt-3">
              
                  <img
                    src={homeInfo.speechImage}
                    alt=""
                    className="w-full"
                    data-aos="zoom-in-up"
                    data-aos-duration="3000"
                  />
                  <div
                    className="font-light mt-1 lg:mt-4"
                    data-aos="zoom-in-right"
                  >
                    <Link to="/ourteam ">
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 10,
                        }}
                        className="box text-[#F1F1F1]  bg-[#E8372C] lg:text-[18px] xl:text-[24px] md:text-[16px] text-[12px] md:px-4 px-3  md:py-2 md:mt-[10px] mt-2 py-2 rounded-[10px]"
                      >
                        {t("MeetOurTeam")}
                      </motion.button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="xl:px-[120px] px-[20px] absolute w-full grid content-center">
                <div className="flex justify-between">
                  <div className="grid lg:right-[120px]">
                    <p className="md:text-[30px] text-[26px] text-[#fff] ">
                      02
                      <span className="md:text-[20px] text-[16px] text-[#E8372C]">
                        /06
                      </span>
                    </p>
                  </div>
                  <div className="flex justify-center w-full">
                    <button
                      className="hidden lg:block text-[#f1f1f1] text-[20px] z-20"
                      onClick={() => handleScrollNext1(cronoasdna)}
                    >
                      <p className="bg-[#24282d]"> &nbsp; {t("next")}</p>
                    </button>
                  </div>
                  <div className="invisible">
                    <p className="text-[30px] ">
                      01<span className="text-[20px] ">/04</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*cronas dna page 3 */}
          <div className="relative pt-10 lg:pt-0">
            <div
              ref={cronoasdna}
              className="h-full lg:min-h-screen text-black pb-20 lg:pb-0 relative bg-cover  items-center justify-center"
            >
              <section className="xl:px-[120px] px-[20px] ">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
                  <div className="flex flex-col md:mt-[50px]  mt-5 mr-40 w-full">
                    <h1
                      className="text-[16px]  xl:text-[24px] lg:text-[17px] font-bold text-[#E8372C] tracking-wider "
                      data-aos="zoom-in-right"
                    >
                      {t("companyOverview")}
                    </h1>
                    {homeInfo && (
                      <p
                        className="text-[#F1F1F1]  text-[16px]  xl:text-[18px] mt-[20px] text-justify xl:leading-[35px]"
                        data-aos="zoom-in-right"
                        yy
                        dangerouslySetInnerHTML={{ __html: homeInfo.overview }}
                      ></p>
                    )}
                    <div className="" data-aos="zoom-in-right">
                      <Link to="/companyoverview">
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          transition={{
                            type: "spring",
                            stiffness: 400,
                            damping: 10,
                          }}
                          className="box text-[#F1F1F1]  bg-[#E8372C] lg:text-[18px] xl:text-[24px] md:text-[16px] text-[12px] md:px-4 px-3  md:py-2 md:mt-[20px] mt-2 py-2 rounded-[10px]"
                        >
                          {t("readMore")}
                        </motion.button>
                      </Link>
                    </div>
                  </div>
                  <div className=" relative md:mt-32 xl:mt-[50px] xl:left-10">
                    <div className="grid justify-center">
                      <div className=" grid relative jus">
                        <img
                          className="lg:w-[520px] xl:w-[620px]  lg:h-[250px] xl:h-[350px] w-[290px] h-[180px]"
                          alt=""
                          src=" /assets/worldmap.png"
                          data-aos="zoom-in-up"
                        />
                        <div
                          className="dot lg:w-[12px] lg:h-[12px] w-[7px] h-[7px]  bg-[#E5362E] rounded-full cursor-pointer text-white absolute xl:left-[295px] xl:top-[116px] lg:left-[230px] lg:top-[82px] left-[137px] top-[60px] "
                          data-aos="zoom-in-up"
                        >
                          <div className="aa">
                            <hr className="border-[#E5362E] border  lg:w-[80px] w-[50px] mt-1 " />
                            <div className=" absolute lg:w-[200px] w-[120px] text-[12px] lg:text-[16px] px-2 lg:px-6 lg:left-[70px] left-[20px] -mt-3 lg:h-[33px] h-[25px] transform  bg-[#E5362E]  text-[#F1F1F1] rounded shadow-lg flex justify-center items-center">
                            {mapLocation[0]?.location}
                            </div>
                          </div>
                        </div>
                        <div
                          className="dot lg:w-[12px] lg:h-[12px] w-[7px] h-[7px]  bg-[#E5362E] rounded-full cursor-pointer text-white absolute xl:left-[385px] xl:top-[170px] lg:left-[300px] lg:top-[120px] left-[175px] top-[87px] "
                          data-aos="zoom-in-up"
                        >
                          <div className="aa">
                            <hr className="border-[#E5362E] border w-[50px] lg:w-[80px] mt-1 " />
                            <div className=" absolute lg:w-[200px] w-[90px] text-[12px] lg:text-[16px] lg:px-5 lg:left-[70px] left-[20px] -mt-3 lg:h-[33px] h-[25px] transform  bg-[#E5362E]  text-[#F1F1F1] rounded shadow-lg flex justify-center items-center">
                            {mapLocation[1]?.location}
                            </div>
                          </div>
                        </div>
                        <div
                          className="dot lg:w-[12px] lg:h-[12px] w-[7px] h-[7px]  bg-[#E5362E] rounded-full cursor-pointer text-white absolute xl:left-[370px] xl:top-[180px] lg:left-[285px] lg:top-[125px] left-[165px] top-[94px]"
                          data-aos="zoom-in-up"
                        >
                          <div className="aa">
                            <hr className="border-[#E5362E] border w-[50px] lg:w-[80px] mt-1 " />
                            <div className=" absolute lg:w-[240px] w-[110px] text-[12px] lg:text-[16px] lg:px-5 lg:left-[70px] left-[20px] -mt-3 lg:h-[40px] h-[25px] transform  bg-[#E5362E]  text-[#F1F1F1] rounded shadow-lg flex justify-center items-center">
                            {mapLocation[2]?.location}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        {homeInfo && (
                          <p
                            className="text-[#F1F1F1] mx-5 text-[16px]   xl:text-[18px] mt-[30px] text-justify xl:leading-[35px]"
                            data-aos="zoom-in-right"
                            yy
                            dangerouslySetInnerHTML={{
                              __html: homeInfo.subOverview,
                            }}
                          ></p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="xl:px-[120px] px-[20px] lg:pt-5 absolute w-full grid content-center">
                <div className="flex justify-between  h-full">
                  <div className="grid content-center">
                    <p className="md:text-[30px] text-[26px] text-[#fff]">
                      03
                      <span className="md:text-[20px] text-[16px] text-[#E8372C]">
                        /06
                      </span>
                    </p>
                  </div>
                  <div className="grid justify-center  w-full  h-full">
                    <button
                      className="hidden lg:block text-[#f1f1f1] text-[20px] z-20"
                      onClick={() => handleScrollNext2(third)}
                    >
                      <p className="bg-[#24282d]"> &nbsp;{t("next")}</p>
                    </button>
                  </div>
                  <div className="invisible">
                    <p className="text-[30px] ">
                      01<span className="text-[20px] ">/04</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*third */}

          <div className="relative lg:h-full">
            <div
              ref={third}
              className="relative h-full   pb-10 border-transparent w-full text-black items-center justify-center"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-0">
                {" "}

                <div className="md:mt-20 lg:mt-16 xl:px-[0px]">
               


                  <img
                    src={homeInfo.missionImage}
                    alt=""
                    className=""
                    data-aos="zoom-in-up"
                    data-aos-duration="3000"
                  />
                

                </div>

                <div className="text-right ml-6 md:mt-20 xl:pr-[120px] pr-[20px]">
                  <h1
                    className="text-[16px] lg:text-[17px] xl:text-[24px] font-bold text-[#E8372C] tracking-wider"
                    data-aos="zoom-in-left"
                  >
                    {t("cronosMission")}
                  </h1>
                  {homeInfo && (
                    <p
                      className="text-[#F1F1F1] text-[16px] xl:text-[18px] mt-[20px] xl:ml-10 text-justify"
                      data-aos="zoom-in-left" dangerouslySetInnerHTML={{ __html: homeInfo.mission }}
                    >
                      {/* {homeInfo.mission} */}
                    </p>
                  )}
                  <div className="" data-aos="zoom-in-left">
                    <Link to="/dna&mission">
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 10,
                        }}
                        className="box text-[#F1F1F1]  bg-[#E8372C] lg:text-[18px] xl:text-[24px] md:text-[16px] text-[12px] md:px-4 px-3 md:py-2 md:mt-[20px] mt-2 py-2 rounded-[10px]"
                      >
                        {t("readMore")}
                      </motion.button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="  w-full  lg:pt-10 lg:bottom-5 xl:px-[120px] px-[20px] bottom-0">
              <div className="flex justify-between ">
                <div className="grid lg:right-[120px] right-5  ">
                  <p className="md:text-[30px] text-[26px] text-[#fff] ">
                    04
                    <span className="md:text-[20px] text-[16px] text-[#E8372C]">
                      /06
                    </span>
                  </p>
                </div>
                <div className="flex justify-center w-full">
                  <button
                    className="hidden lg:block text-[#f1f1f1] text-[20px] z-20"
                    onClick={() => handleScrollNext3(fourth)}
                  >
                    <p className="bg-[#24282d]"> &nbsp; {t("next")}</p>
                  </button>
                </div>
                <div className="invisible">
                  <p className="text-[30px] ">
                    01<span className="text-[20px] ">/04</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/*fourth  */}

          <div className="relative  h-full lg:min-h-screen" id="news">
            <div
              ref={fourth}
              className=" xl:px-[120px] lg:px-[20px] relative border-transparent w-full text-black items-center justify-center"
            >
              <div className=" grid grid-cols-1 md:grid-cols-2 gap-4">
                {" "}
                <div className="">
                  <p
                    className="text-[16px] lg:text-[17px]  pl-5 lg:pl-0 xl:text-[24px]  mt-6 font-bold text-[#E8372C] tracking-wider"
                    data-aos="zoom-in-right"
                    data-aos-duration="500"
                  >
                    {t("news")}
                  </p>
                  {/* {homeInfo && (
                    <p
                      className="text-[#F1F1F1] font-extralight text-[16px]  mt-2 text-justify lg:mt-[20px] xl:leading-[35px] hidden lg:block"
                      data-aos="zoom-in-right"
                      data-aos-duration="500"
                    >
                      {homeInfo.news}
                    </p>
                  )} */}

                  <div className="grid grid-cols-2 mt-10 "></div>
                </div>
              </div>

              {/* map card */}

              <div className="grid lg:grid-cols-4 bo grid-cols-2 gap-2 justify-between -mt-5 lg:mt-0 xl:ml-10 lg:ml-[14px] md:ml-20  mx-5 md:mx-10 lg:mx-0">
                {newsSections.map((imageUrl, index) => (
                  <Link to={`/newscard?title=${imageUrl.title}`}>
                    <div
                      key={index}
                      className="flex justify-between"
                      data-aos="zoom-in"
                    >
                      <div className="w-[275px] group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                        <div className="w-[275px]">
                          <img
                            className="h-full w-full object-cover transition-transform duration-500 group-hover:scale-110"
                            src={imageUrl.image}
                            alt=""
                          />
                        </div>
                        <div className=" absolute inset-0  bg-gradient-to-b from-black via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                        <div className="hidden lg:block absolute inset-0 translate-y-[90%] flex-col ml-3 mt-2 transition-all duration-500 group-hover:translate-y-0">
                          <p className="mb-3 text-lg text-[#F1F1F1] opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                            {imageUrl.title}
                          </p>
                          <button className="w-[100px] rounded-full bg-[#E5362E] py-2 px-3.5 font-com text-sm capitalize text-[#F1F1F1] shadow ">
                            {t("readMore")}
                          </button>
                        </div>
                        <div className="lg:hidden  absolute inset-0 flex items-center translate-y-[30%] flex-col ml-3 mt-2 transition-all duration-500 group-hover:translate-y-0">
                          <p className="mb-1 h-[40px] text-[14px] text-[#F1F1F1] transition-opacity duration-300  ">
                            {imageUrl.title}
                          </p>
                          <button className="w-[100px] rounded-full bg-[#E5362E] py-2 px-3.5 font-com text-[10px] capitalize text-[#F1F1F1] shadow ">
                            {t("readMore")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>

              <Link to="/newsmore">
                <div className="w-full justify-center content-center pl-5  hidden lg:flex">
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    className="z-20 box text-[#F1F1F1]  bg-[#E8372C] lg:text-[18px] xl:text-[24px] md:text-[16px] text-[12px]] md:px-4 px-3  md:py-2 md:mt-[20px] mt-2 py-2 rounded-[10px] tracking-wider"
                  >
                    {t("more")}
                  </motion.button>
                </div>
              </Link>

              <Link to="/newsmore">
                <div className="w-full justify-center content-center pl-5 lg:hidden flex">
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    className="z-20 box text-[#F1F1F1]  bg-[#E8372C] lg:text-[20px] xl:text-[24px] md:text-[16px] text-[12px] md:px-4 px-3  md:py-2 md:mt-[20px] mt-2 py-2 rounded-[10px] tracking-wider"
                  >
                    {t("more")}
                  </motion.button>
                </div>
              </Link>
            </div>
            <div className="grid mt-10  lg:pt-5 content-end ">
              <div className="xl:px-[120px] px-[20px] absolute w-full grid content-center ">
                <div className="flex justify-between">
                  <div className="grid content-center">
                    <p className="md:text-[30px] text-[26px] text-[#fff]">
                      05
                      <span className="md:text-[20px] text-[16px] text-[#E8372C]">
                        /06
                      </span>
                    </p>
                  </div>
                  <div className="grid justify-center w-full">
                    <button
                      className="hidden lg:block text-[#f1f1f1] text-[20px] z-20"
                      onClick={() => handleScrollNext4(contact)}
                    >
                      <p className="bg-[#24282d]"> &nbsp; {t("next")}</p>
                    </button>
                  </div>
                  <div className="invisible">
                    <p className="text-[30px] ">
                      01<span className="text-[20px] ">/04</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* contact */}
          <div
            ref={contact}
            id="contactt"
            conatct_id="true"
            className="lg:min-h-screen h-full lg:pt-5 relative w-full text-black bg-cover  overflow-y-hidden  xl:px-[120px] px-[20px]"
          >
            <div className="grid lg:grid-cols-2 grid-cols-1 pt-[30px]  xl:px-0 ">
              <div className="mr-5 hidden lg:block">
                <h2 className="text-[16px] lg:text-[17px]  xl:text-[24px]   font-bold text-[#E8372C] tracking-wider">
                  {t("reachUs")}
                </h2>
                {contactSections.map((section, index) => (
                  <div className={`relative w-full`} key={index}>
                    <input
                      type="checkbox"
                      className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                      onClick={() => toggleDropdown(index)}
                    />
                    <div className=" h-12 w-full flex items-center">
                      <p className="text-[#F1F1F1] md:mb-2 font-medium  text-[16px] md:text-[20px]">
                        {section.country}
                      </p>
                    </div>
                    <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3">
                      {openDropdownIndex === index ? (
                        <span
                          className={`material-icons material-symbols-outlined ${openDropdownIndex === index
                              ? "icon-expand-less"
                              : "icon-expand-more"
                            }`}
                        >
                          expand_less
                        </span>
                      ) : (
                        <span
                          className={`material-icons material-symbols-outlined ${openDropdownIndex === index
                              ? "icon-expand-less"
                              : "icon-expand-more"
                            }`}
                        >
                          expand_more
                        </span>
                      )}
                    </div>

                    <div
                      className={`overflow-hidden transition-all duration-500 max-h-0 ${openDropdownIndex === index ? "max-h-60" : ""
                        }`}
                    >
                      {/* <p className="leading-5 md:leading-8 font-bold text-[#E8372C]">
                        <span className="leading-5 md:leading-8 font-medium  text-white w-[20px] text-justify">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Mauris sagittis ligula nec lacinia aliquet.
                          Aliquam eget vulputate magna. Integer tempus fringilla
                          ante, quis molestie augue fermentum ut.
                        </span>
                      </p>{" "} */}

                      <div className="w-full flex">
                        <div className="text-[#f1f1f1f1] lg:mt-4 ">
                          <div className="text-[16px] lg:text-[18px] lg:w-[250px] w-[220px] md:w-[230px]">
                            <p className="leading-5 md:leading-8 font-bold text-[#E8372C]" >
                              Address:{" "}
                              <span className="leading-5 md:leading-8 inline font-medium text-white w-[20px] " dangerouslySetInnerHTML={{
                                __html: section.address
                              }}
                              >

                                {/* // {section.address} */}
                              </span>
                            </p>{" "}
                            <p className="leading-5 md:leading-8 font-bold text-[#E8372C]">
                              {" "}
                              E-mail:{" "}
                              <span className="leading-5 md:leading-8 font-medium text-white">
                                {section.email}
                              </span>{" "}
                            </p>
                            <p className="leading-5 md:leading-8 font-bold text-[#E8372C]">
                              {" "}
                              Tel:{" "}
                              <span className="leading-5 md:leading-8 font-medium text-white">
                                {" "}
                                {section.phone}
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                        <div className="relative ml-10 w-[45%] aspect-[16/9]">
                          <iframe
                            title={`map-${section.country}`}
                            src={section.location}
                            className="absolute top-0 left-0 w-full h-full rounded-2xl"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* career hirring */}

                <h2 className="xl:mt-20 lg:mt-10 lg:text-[17px]  text-[16px] xl:text-[24px]   font-bold text-[#E8372C] tracking-wider">
                  {t("hire")}
                </h2>
                {hiringSections.map((section, index) => (
                  <div className={`relative w-full`} key={index}>
                    <input
                      type="checkbox"
                      className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                      onClick={() => toggleDropdown1(index)}
                    />
                    <div className=" h-12 w-full flex items-center">
                      <p className="text-[#F1F1F1] md:mb-2 font-medium  text-[16px] md:text-[20px]">
                        {section.title}
                      </p>
                    </div>
                    <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3">
                      {openDropdownIndex1 === index ? (
                        <span
                          className={`material-icons material-symbols-outlined ${openDropdownIndex1 === index
                              ? "icon-expand-more"
                              : "icon-expand-less"
                            }`}
                        >
                          expand_less
                        </span>
                      ) : (
                        <span
                          className={`material-icons material-symbols-outlined ${openDropdownIndex1 === index
                              ? "icon-expand-more"
                              : "icon-expand-less"
                            }`}
                        >
                          expand_more
                        </span>
                      )}
                    </div>

                    <div
                      className={`overflow-hidden transition-all duration-500 max-h-0 ${openDropdownIndex1 === index ? "max-h-60" : ""
                        }`}
                    >
                      <p className="leading-5 md:leading-8 font-bold text-[#E8372C]">
                        <span className="leading-5 md:leading-8 font-medium  text-white w-[20px] text-justify"  dangerouslySetInnerHTML={{
                                __html: section.description
                              }} >
                          {/* {section.description} */}
                        </span>
                      </p>{" "}
                      <div className="flex items-center">
                        <a
                          href={section.pdfUrl} // Replace with the document URL
                          target="_blank" // Open in a new tab
                          rel="noopener noreferrer" // Recommended for security
                        >
                          <button className="leading-5 md:leading-8 font-bold mt-2 mb-2  cursor-pointer w-[100px] rounded-full bg-[#E5362E] py-2 px-3.5 font-co4m text-sm capitalize text-[#F1F1F1] shadow">
                            Download
                          </button>
                        </a>
                      </div>
                      <a href="https://mail.google.com" target="_blank">
                        {" "}
                        <p className="leading-5 md:leading-8 font-bold text-[#E8372C] cursor-pointer">
                          {" "}
                          E-mail:{" "}
                          <span className="leading-5 md:leading-8 font-medium text-white cursor-pointer">
                          {section.email}{" "}
                         </span>{" "}
                        </p>{" "}
                      </a>{" "}
                    </div>
                  </div>
                ))}
              </div>

              {/* mobile */}

              <div className="block lg:hidden">
                <h2 className="mt-5 text-[16px] xl:text-[24px] lg:text-[24px]  font-semibold text-[#E8372C] tracking-wider">
                  {t("reachUs")}
                </h2>
                {contactSections.map((section, index) => (
                  <div className={`relative w-full`} key={index}>
                    <input
                      type="checkbox"
                      className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                      onClick={() => toggleDropdown(index)}
                    />
                    <div className=" h-12 w-full flex items-center">
                      <p className="text-[#F1F1F1] md:mb-2 font-semibold lg:text-[30px] text-[16px] md:text-[20px]">
                        {section.country}{" "}
                      </p>
                    </div>
                    <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3">
                      {openDropdownIndex1 === index ? (
                        <span
                          className={`material-icons material-symbols-outlined ${openDropdownIndex1 === index
                              ? "icon-expand-more"
                              : "icon-expand-less"
                            }`}
                        >
                          expand_less
                        </span>
                      ) : (
                        <span
                          className={`material-icons material-symbols-outlined ${openDropdownIndex1 === index
                              ? "icon-expand-more"
                              : "icon-expand-less"
                            }`}
                        >
                          expand_more
                        </span>
                      )}
                    </div>
                    <div
                      className={`overflow-hidden transition-all duration-500 max-h-0 ${openDropdownIndex === index ? "max-h-60" : ""
                        }`}
                    >
                      {" "}
                      <div className="w-full grid grid-cols-1 md:grid-cols-2">
                        <div className="text-[#f1f1f1f1] lg:mt-4 ">
                          <div className="text-[16px] lg:text-[18px]">
                            <p className="leading-5 md:leading-8 font-bold text-[#E8372C] ">
                              Address:
                              <span className="ml-1 leading-5 md:leading-8 font-medium text-white w-[20px]">
                                {section.address}
                              </span>
                            </p>{" "}
                            <p className=" leading-5 mt-2 md:leading-8 font-bold text-[#E8372C]">
                              {" "}
                              E-mail:{" "}
                              <span className="ml-1 leading-5 md:leading-8 font-medium text-white">
                                {section.email}
                              </span>{" "}
                            </p>
                            <p className="leading-5 mt-2 md:leading-8 font-bold text-[#E8372C]">
                              {" "}
                              Tel:{" "}
                              <span className="ml-1 leading-5 md:leading-8 font-medium text-white">
                                {" "}
                                {section.phone}
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                        <div className="mt-2">
                          <iframe
                            title={`map-${section.country}`}
                            src={section.location}
                            className="mt-1 xl:w-[230px] xl:h-[210px] lg:w-[155px] lg:h-[200px] md:w-[260px] w-[150px] h-[120px] ml-2 rounded-xl"
                          ></iframe>
                          {/* <img src='/assets/map1.png' className=' md:w-[260px] w-[140px] h-[120px] ml-2 p-2'></img> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className=" lg:hidden">
                {/* career hirring */}
                <h2 className="mt-5 text-[16px] xl:text-[24px] lg:text-[24px]  font-semibold text-[#E8372C] tracking-wider">
                  {t("hire")}
                </h2>
                {hiringSections.map((section, index) => (
                  <div className={`relative w-full`} key={index}>
                    <input
                      type="checkbox"
                      className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer"
                      onClick={() => toggleDropdown1(index)}
                    />
                    <div className=" h-12 w-full flex items-center">
                      <p className="text-[#F1F1F1] md:mb-2 font-medium lg:text-[24px] text-[16px] md:text-[20px]">
                        {section.title}{" "}
                      </p>
                    </div>
                    <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3">
                      {openDropdownIndex1 === index ? (
                        <span
                          className={`material-icons material-symbols-outlined ${openDropdownIndex1 === index
                              ? "icon-expand-more"
                              : "icon-expand-less"
                            }`}
                        >
                          expand_less
                        </span>
                      ) : (
                        <span
                          className={`material-icons material-symbols-outlined ${openDropdownIndex1 === index
                              ? "icon-expand-less"
                              : "icon-expand-more"
                            }`}
                        >
                          expand_more
                        </span>
                      )}
                    </div>
                    <div
                      className={`overflow-hidden transition-all duration-500 max-h-0 ${openDropdownIndex1 === index ? "max-h-60" : ""
                        }`}
                    >
                      <p className="leading-5 md:leading-8 font-bold text-[#E8372C]">
                        <span className="leading-5 md:leading-8 font-medium  text-white w-[20px] text-justify" dangerouslySetInnerHTML={{
                                __html: section.description
                              }} >
                          {/* {section.description} */}
                        </span>
                      </p>{" "}
                      <div className="flex items-center">
                        <a
                          href={section.pdfUrl}
                          L
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="leading-5 mt-2 md:leading-8 font-bold cursor-pointer w-[100px] rounded-full bg-[#E5362E] py-2 px-3.5 font-co4m text-sm capitalize text-[#F1F1F1] shadow">
                            Download
                          </button>
                        </a>
                      </div>
                      <a href="https://mail.google.com" target="_blank">
                        {" "}
                        <p className="mt-2 leading-5 md:leading-8 font-bold text-[#E8372C] cursor-pointer">
                          {" "}
                          E-mail:{" "}
                          <span className="leading-5 md:leading-8 font-medium text-white cursor-pointer">
                            {section.email}{" "}
                          </span>{" "}
                        </p>
                      </a>
                    </div>
                  </div>
                ))}
              </div>

              {/* form */}

              <div className=" flex justify-center">
                <div className="md:ml-10">
                  <h2 className="text-[16px] xl:text-[24px] lg:text-[17px]   xl:-mt-5 mb-5 font-bold text-[#E8372C] tracking-widest">
                    {t("contact")}
                  </h2>
                  <form
                    className="lg:mt-5 mt-2 xl:w-[536px] md:w-[380px] w-[280px]"
                    onSubmit={handleSubmit}
                  >
                    <div className="inputbox">
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleInputChange}
                        required
                      />
                      <span>{t("fullName")}</span>
                      <i></i>
                    </div>
                    {/* mail  */}
                    <div className="inputbox">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        required
                      />
                      <span>{t("email")}</span>
                      <i></i>
                    </div>
                    <div className="inputbox">
                      <input
                        type="tel"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleInputChange}
                        required
                      />
                      <span>{t("contactNum")}</span>
                      <i></i>
                    </div>
                    {/* subject  */}
                    <div className="inputbox">
                      <input
                        type="text"
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                        required
                      />
                      <span>{t("subject")}</span>
                      <i></i>
                    </div>

                    <textarea
                      className="w-full lg:h-[150px] h-[70px] md:h-[110px] xl:h-[190px]  p-2  text-[14px] rounded-md mb-2 bg-transparent border border-[#E8372C]"
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      placeholder={t("message")}
                      required
                    ></textarea>
                    <button
                      type="submit"
                      className="bg-[#F23C25] text-[#F1F1F1] w-full h-[30px] lg:h-[52px] text-[14px] font-semibold px-2 rounded"
                    >
                      {t("send")}
                    </button>
                  </form>
                  {isFormSubmitted && (
                    <p className="text-green-500 mt-2">
                      Form submitted successfully!
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex justify-center pb-10 lg:pt-10">
              <div className="absolute w-full lg:bottom-3  xl:px-[120px] px-[20px]">
                <div className="flex justify-between">
                  <div className="grid content-center">
                    <p className="md:text-[30px] text-[26px] text-[#fff]">
                      06
                      <span className="md:text-[20px] text-[16px] text-[#E8372C]">
                        /06
                      </span>
                    </p>
                  </div>
                  <div className="grid justify-center w-full">
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 10,
                      }}
                      onClick={handleScrollToTop}
                      className="text-[#f1f1f1] hidden hover:bg-[#F23C25] lg:block border-[3px] text-xs md:text-[20px] transform md:w-[180px] md:h-[50px] bg-[#24282d] z-20 py-2 px-2 xl:ml-3 rounded-xl border-[#F23C25]"
                    >
                      {t("moveTop")}
                    </motion.button>
                  </div>
                  <div className="invisible">
                    <p className="text-[30px] ">
                      01<span className="text-[20px] ">/04</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="xl:px-[120px] px-[20px] bg-gradient-to-r from-[#28313B] to-[#202020] h-40 pt-10">
        <hr className="py-2 border-[#F23C25]" />
        <div className="lg:flex justify-between">
          <div className="flex justify-center">
            {/* <img src="./assets/facebook.png" alt="" className="w-[30px] md:w-[40px] lg:w-[50px]" /> */}
            {/* <img src="./assets/linkdin.png" alt="" className="w-[30px] md:w-[40px] lg:w-[50px] ml-5" /> */}
            <a
              href="https://www.linkedin.com/company/cronos-conseil/about/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                src="./assets/linkdin.png"
                alt=""
                className="w-[30px] md:w-[40px] lg:w-[50px] "
              />
            </a>
            {/* <img src="./assets/insta.png" alt="" className="w-[30px] md:w-[40px] lg:w-[50px] ml-5" /> */}
          </div>
          <div className="py-3">
            <p className="text-[10px] text-center md:text-[12px] lg:text-[14px] text-[#f1f1f1] opacity-[60%]">
              &copy; Cronos international- {new Date().getFullYear()}. All rights reserved, Designed &
              Developed by Meerana Technologies LLP
            </p>
          </div>
        </div>
      </div>
    </>
  );
});

export default Herosection;