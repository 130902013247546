import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import axios from 'axios';

export default function NavAbout({ isOpen }) {


      const { t } = useTranslation();
      const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD; 


 const [aboutusInfo,setAboutusInfo] = useState([]);
  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`${baseUrl}/get_homepage.php`, {
        headers: {
          "Accept-Language": t('lang'), // Set the accepted language in the headers
        },
      })
      .then((response) => {
        console.log("API response data:", response.data); // Log the API response
      
          setAboutusInfo(response.data[0]); // Set the first description        
      })
      .catch((error) => {
        console.error("Error fetching offers:", error);
      });
  }, []);


  const [dnaSections, setDnaSections] = useState([]);
  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`${baseUrl}/get_dna_mission.php`, {
        headers: {
          "Accept-Language": t('lang'), // Set the accepted language in the headers
        },
      })
      .then((response) => {
        console.log("API response data:", response.data); // Log the API response
        const sections = response.data;
        if (sections.length > 0) {
          setDnaSections(sections[0].description); // Set the first description
        } // Assuming the API response is the array of sections
      })
      .catch((error) => {
        console.error("Error fetching offers:", error);
      });
  }, []);
  //

  return (
    <>
      <div className="hidden md:flex justify-center xl:mx-32 mx-[20px] -mt-20 overflow-y-scroll h-[70vh] pr-5">
        <div className="mt-0">
        <p className=" lg:text-[#F1F1F1] xl:text-[24px]  text-[17px] tracking-wider"> {t('companyOverview')}</p>
          <p className="text-[#F1F1F1] text:[16px] xl:text-[18px] text-justify xl:leading-8"dangerouslySetInnerHTML={{ __html: aboutusInfo.overview }}></p>
          <Link to="/companyoverview"> <button className=" border-2 border-white text-[#F1F1F1] xl:text-[17px] text-[10px] mt-5 px-6 py-2 rounded-[8px] ">{t('readMore')}</button></Link> 
         <p className="text-[#F1F1F1] text-[17px]  xl:text-[24px] xl:mt-10 mt-2"> {t('cronosMission')}</p>

          <p className="text-[#F1F1F1] text-[16px] xl:text-[18px] text-justify xl:leading-8"dangerouslySetInnerHTML={{ __html: aboutusInfo.mission }}></p>
          <Link to="/dna&mission">   <button className=" border-2 border-white text-[#F1F1F1] xl:text-[17px] text-[10px] mt-5 px-6 py-2 rounded-[8px] ">{t('readMore')}</button></Link> 
        </div>
      </div>
    </>
  )
}
