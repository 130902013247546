import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavOffer from "../navmenu/nav-offer";
import NavAbout from "../navmenu/nav-about";
import NavBusiness from "../navmenu/nav-business";
import NavAchieve from "../navmenu/nav-achive";
import NavNews from "../navmenu/nav-news";
import { useTranslation } from 'react-i18next';
import axios from 'axios';


export default function NavbarList({
  isOpen,
  closeNav,
  toggleNavAbout,
  toggleNavBusiness,
  toggleNavOffer,
  toggleOurTeam,
  toggleNavAchieve,
  toggleNavNews,

  toggleContactUs,
  isNavAboutOpen,
  isNavBusinessOpen,
  isNavOfferOpen,
  isOurTeamOpen,
  isNavAchieveOpen,
  isNavNewsOpen,
  isContactUsOpen,
  setIsOpen,
  menu, props }) {
  const navigate = useNavigate();

  const handleNewsClick = () => {
    // toggleContactUs(); // Assuming toggleContactUs handles the state change for contact section
    closeNav(); // Close the navigation menu
  };

  const { t } = useTranslation();

  const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD;

  const [offerSections, setOfferSections] = useState([]);


  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`${baseUrl}/get_offers.php`, {
        headers: {
          "Accept-Language": t('lang'), // Set the accepted language in the headers
        },
      })
      .then((response) => {
        console.log("API response data:", response.data); // Log the API response
        const sections = response.data; // Assuming the API response is the array of sections
        setOfferSections(sections); // Set the complete sections array


      })
      .catch((error) => {
        console.error("Error fetching offers:", error);
      });
  }, []);


  const [achieveSections, setAchieveSections] = useState([]);


  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`${baseUrl}/get_achievements.php`, {
        headers: {
          "Accept-Language": t('lang'), // Set the accepted language in the headers
        },
      })
      .then((response) => {
        console.log("API response data:", response.data); // Log the API response
        const sections = response.data; // Assuming the API response is the array of sections
        setAchieveSections(sections); // Set the complete sections array

      })
      .catch((error) => {
        console.error("Error fetching offers:", error);
      });
  }, []);
  const handleNavItemClick = () => {
    closeNav(); // Call the closeNav function passed as a prop
  };


  // const navigate = useNavigate();
  const scrollToSection = (index) => {
    navigate("/securityrisk");
    setTimeout(() => {
      const sectionId = `section-${index}`;
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: "smooth" });
        props.closeNav();
      }
    }, 100); // Delay for smoother scroll
  }


  return (
    <>
      <div className={`hidden lg:flex fixed   top-0 z-40 right-0 bottom-0 left-0 mx-auto overflow-y-auto transition-transform duration-500 ease-in-out ${isOpen ? "transform  translate-y-0" : "transform translate-y-[100%]"}`} style={{ transitionDelay: isOpen ? "0ms" : "900ms" }}
      >


        <div
          className={`relative  top-0 z-20  right-0  bg-[#F23C25] h-[100vh]  md:w-[205px] w-[140px]  transform transition-transform duration-1000 ease-in-out ${isOpen ? "transform translate-y-0 " : "-translate-y-[100%] transform"

            }`}
          style={{ transitionDelay: isOpen ? "300ms" : "300ms" }}>
          <div className="">
            <Link to="/"><img src="/assets/logo.png" alt="" className="w-[80px] md:w-[150px] md:mt-10 md:ml-9 ml-2  " onClick={closeNav} /></Link>
          </div>

        </div>
        <div className="bg-[#F23C25] h-[100vh]  w-full">
          <div className="flex justify-between">
            <div className=""></div>
            <div className="invisible lg:ml-60"></div>
            <div className='relative cursor-pointer bg-white rounded-full grid content-center p-1 xl:mr-[120px] mr-[26px] xl:mt-10 mt-[60px]' onClick={closeNav}>
              <span class="material-symbols-outlined text-[#E5362E] !text-[30px]  xl:!text-[38px] cursor-pointer">
                close
              </span>
            </div>

          </div>
          {/* Vertical line */}
          <div >
            <div className="hidden lg:flex  overflow-x-hidden ">
              <div className={`md:h-screen top-0 md:ml-[40px] h-full border-2 border-r border-white absolute ${isOpen ? "transform translate-y-0 duration-1000 ease-in-out" : "-translate-y-[100%] duration-500 ease-in-out transform"

                } `} style={{ transitionDelay: isOpen ? "1000ms" : "100ms" }} ></div>
              {/* li list */}
              <div className={`flex flex-col ml-[10px] md:ml-[80px] md:mt-[30px] mt-[80px] transition-transform duration-700 ease-in-out  ${isOpen ? "transform translate-x-0" : "transform translate-x-[2500px]"}`} style={{ transitionDelay: isOpen ? "1300ms" : "0ms" }} >
                <ul className="list-none lg:w-[370px] " style={{ padding: 0, margin: 0 }}>

                  <li className="md:text-[20px] text-[#FFFFFF] py-3 lg:text-[24px] flex">
                    <label className="cursor-pointer"><p htmlFor="agree text-black " onClick={toggleNavAbout}  >{t('aboutUs')}
                    </p></label>
                  </li>

                  <li className="md:text-[20px] text-[#FFFFFF] md:py-3 py-2 lg:text-[24px]" onClick={toggleNavBusiness}>


                    <label className="cursor-pointer"><p htmlFor="agree text-black "  >{t('business')}

                    </p></label>



                  </li>

                  <li className="md:text-[20px] text-[#FFFFFF] md:py-3 py-2 lg:text-[24px]" onClick={toggleNavOffer} >


                    <label className="cursor-pointer"><p htmlFor="agree text-black "  >{t('businessLines')}

                    </p></label>


                  </li>
                  <li className="md:text-[20px] text-[#FFFFFF] md:py-3  py-2 lg:text-[24px]" onClick={toggleNavAchieve}>


                    <label className="cursor-pointer"><p htmlFor="agree text-black "  >{t('achievement')}

                    </p></label>

                  </li>



                  <li className="md:text-[20px] text-[#FFFFFF] md:py-3 py-2 lg:text-[24px]" onClick={toggleOurTeam}>
                    <Link to="/ourteam">  <label className="cursor-pointer"><p htmlFor="agree text-black"  >{t('ourTeam')}

                    </p></label></Link></li>
                  <li
                    className="md:text-[20px] text-[#FFFFFF] md:py-3 py-2 lg:text-[24px]"
                  >
                    <Link to="/news">
                      {" "}
                      <label className="cursor-pointer">
                        <p htmlFor=" agree text-black ">
                          {t('news')}

                        </p>
                      </label>
                    </Link>
                  </li>

                  <li className="md:text-[20px] text-[#FFFFFF] md:py-3 py-2 lg:text-[24px]" >
                    <Link to="/contactus">  <label className="cursor-pointer"><p htmlFor="agree text-black "  >{t('contact')}

                    </p></label></Link>

                  </li>
                </ul>
              </div>
              {/* start list */}
              <div className={`flex justify-center w-full`}>
                <div className="hidden md:block w-full mt-[100px]  max-h-[calc(100vh - 200px)] ">
                  <div className={`    transform  ${isNavAboutOpen ? "-translate-x-0  " : "translate-x-[100%] "
                    } duration-500 ease-in-out`}  >
                    {isNavAboutOpen && <NavAbout isOpen={isNavAboutOpen} />}
                  </div>
                  <div className={` transition-transform duration-500  ${isNavBusinessOpen ? "transform translate-x-0 " : " transform translate-x-full"}`}>
                    {isNavBusinessOpen && <NavBusiness isOpen={isNavBusinessOpen} />}</div>

                  <div className={` transition-transform duration-500  ${isNavOfferOpen ? "transform translate-x-0 " : " transform translate-x-full"}`}>


                    {isNavOfferOpen && <NavOffer isOpen={isNavOfferOpen} closeNav={closeNav} />} </div>

                  <div className={` transition-transform duration-500  ${isNavAchieveOpen ? "transform translate-x-0 " : " transform translate-x-full"}`}>
                    {isNavAchieveOpen && <NavAchieve closeNav={closeNav} />}</div>

                </div>
              </div>

              {/* end list */}
            </div>
            {/* li end */}
          </div>

        </div>

      </div>

      {/* mobile */}
      <div className={`lg:hidden h-full fixed top-0 z-40 right-0 bottom-0 left-0 mx-auto overflow-y-auto transition-transform duration-500 ease-in-out ${isOpen ? "transform  translate-x-0" : "transform translate-x-[100%]"}`} style={{ transitionDelay: isOpen ? "0ms" : "0ms" }}
      >

        <div className="relative top-0 z-20 right-0  bg-[#F23C25] !min-h-[150vh] w-full transform transition-transform duration-1000 ease-in-out">
          <div className="flex justify-between mx-3 pt-6">
            <div className="">
              <Link to="/"><img src="/assets/logo.png" alt="" className="w-[100px] md:w-[150px] " onClick={closeNav} /></Link>
            </div>

            <div className=" text-[#F1F1F1] cursor-pointer bg-white rounded-full grid content-center p-1 md:px-2 md:h-[42px]" onClick={closeNav} >
              <span class="material-symbols-outlined text-[#E5362E] !text-[30px] md:!text-[26px]  cursor-pointer">
                close
              </span>
            </div>
          </div>


          <div className="absolute top-32 w-full pl-3">
            <ul className="list-none" style={{ padding: 0, margin: 0 }}>
              <li className="relative w-full">
                <input type="checkbox" className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer" />
                <div className=" h-12 w-full flex items-center">
                  <p className='text-[#F1F1F1] md:mb-2 font-medium lg:text-[24px] text-[16px] md:text-[20px]'>{t('aboutUs')}</p>
                </div>
                <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3 peer-checked:hidden">
                  <span className="materiak-icons material-symbols-outlined">expand_more</span>
                </div>
                <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3 peer-checked:block hidden">
                  <span className="material-icons material-symbols-outlined">expand_less</span>
                </div>
                <div className=" overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-60">
                  <div className="w-full block text-[#f1f1f1] ml-3 text-[13px] md:text-[14px]">
                    <Link to='/companyoverview'> <p>{t('companyOverview')}</p> </Link>
                    <Link to='/dna&mission'> <p className="mt-1">{t('cronosMission')}</p> </Link>
                  </div>
                </div>
              </li>

              <li className="md:text-[20px] text-[#F1F1F1] md:py-3 py-2 lg:text-[24px]" >
                <Link to='/business'><label className="cursor-pointer"><p htmlFor="agree text-black "  >{t('business')}
                </p></label></Link>
              </li>

              <li className="relative w-full">
                <input type="checkbox" className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer" />
                <div className=" h-12 w-full flex items-center">
                  <p className='text-[#F1F1F1] md:mb-2 font-medium lg:text-[24px] text-[16px] md:text-[20px]'>{t('businessLines')}</p>
                </div>
                <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3 peer-checked:hidden">
                  <span className="materiak-icons material-symbols-outlined">expand_more</span>
                </div>
                <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3 peer-checked:block hidden">
                  <span className="material-icons material-symbols-outlined">expand_less</span>
                </div>
                <div className=" overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-[55vh]">
                  {offerSections.map((navList, index) => (
                    <Link to={`/securityrisk?title=${navList.title}`}><div className="w-full block text-[#f1f1f1] ml-3 mt-1">
                      <p className="mt-1 text-[13px] md:text-[14px]" onClick={() => {
                        scrollToSection(index);
                        closeNav(); 
                        
                      }}> {navList.title}</p>
                    </div> </Link>
                  ))}
                </div>
              </li>

              <li className="relative w-full">
                <input type="checkbox" className="peer absolute top-0 inset-x-0 w-full h-12 opacity-0 z-10 cursor-pointer" />
                <div className=" h-12 w-full flex items-center">
                  <p className='text-[#F1F1F1] md:mb-2 font-medium lg:text-[24px] text-[16px] md:text-[20px]'>{t('achievement')}</p>
                </div>
                <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3 peer-checked:hidden">
                  <span className="materiak-icons material-symbols-outlined">expand_more</span>
                </div>
                <div className="text-[#F1F1F1] text-2xl absolute top-2 right-3 peer-checked:block hidden">
                  <span className="material-icons material-symbols-outlined">expand_less</span>
                </div>
                <div className=" overflow-hidden transition-all duration-500 max-h-0 peer-checked:max-h-[50vh]">

                  {achieveSections.map((navAchievements, index) => (
                    <Link to={`/ourachievecard?title=${navAchievements.title}`}><div className="w-full block text-[#f1f1f1] ml-3 mt-1">
                      <p className="mt-1 text-[13px] md:text-[14px]" onClick={handleNavItemClick}>{navAchievements.title}</p>
                    </div></Link>
                  ))}

                </div>
              </li>

              <li className="md:text-[20px] text-[#F1F1F1] md:py-3 py-2 lg:text-[24px]" onClick={toggleOurTeam}>
                <Link to="/ourteam">  <label className="cursor-pointer"><p htmlFor="agree text-black"  >{t('ourTeam')}
                </p></label></Link>
              </li>

              <li
                className="md:text-[20px] text-[#F1F1F1] md:py-3 py-2 lg:text-[24px]"
              >
                <Link to="/news">
                  {" "}
                  <label className="cursor-pointer">
                    <p htmlFor=" agree text-black ">
                      {t('news')}

                    </p>
                  </label>
                </Link>
              </li>

              <li className="md:text-[20px] text-[#F1F1F1] md:py-3 py-2 lg:text-[24px]" >
                <Link to="/contactus">  <label className="cursor-pointer"><p htmlFor="agree text-black "  >{t('contact')}
                </p></label></Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
