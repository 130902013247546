import React, { useEffect, useState } from 'react'
import SubNavbar from '../../subnavbar'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { useTranslation } from 'react-i18next';

export default function NewsMore() {
  const { t } = useTranslation(); 

  const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD; 

   // news
   const [newsSections, setNewsSections] = useState([]);


   useEffect(() => {
       axios
           .get(`${baseUrl}/get_news.php`, {
               headers: {
                   "Accept-Language": t('lang'), // Set the accepted language in the headers
               },
           })
           .then((response) => {
               console.log("API response data:", response.data);
               const sections = response.data; // Assuming the API response is the array of sections
               setNewsSections(sections); // Set the complete sections array


           })
           .catch((error) => {
               console.error("Error fetching offers:", error);
           });
   }, []);

  return (
    <>
      <div className="xl:px-[120px] h-full lg:h-screen px-5 bg-gradient-to-r from-[#28313B] to-[#202020]">
        <div className="relative z-20 ">
          <SubNavbar/>
        </div>
        <div className="flex justify-center">
        <div className=" grid md:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3 gap-5 justify-between mt-20 ">

{newsSections.map((imageUrl, index) => (

    
        <div key={index} className="">
            <div className="w-[275px] group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                <div className="w-[275px]">
                    <img
                        className="h-[220px] w-[275px] object-cover transition-transform duration-500 group-hover:scale-110"
                        src={imageUrl.image}
                        alt=""
                    />
                </div>
                <div className="hidden lg:block absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                <Link to={`/newscard?title=${imageUrl.title}`}>
                <div className="hidden lg:block absolute inset-0 translate-y-[90%] flex-col ml-3 mt-2 transition-all duration-500 group-hover:translate-y-0">
                    <p className="mb-3 text-lg text-[#F1F1F1] opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                        {imageUrl.title}                                                </p>
                    <button className="w-[100px] rounded-full bg-[#E5362E] py-2 px-3.5 font-com text-sm capitalize text-[#F1F1F1] shadow ">
                        {t('readMore')}
                    </button>
                </div>
                </Link>
                {/* mobile  */}
                <div className="lg:hidden absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black "></div>
                <Link to={`/newscard?title=${imageUrl.title}`}>
                <div className="lg:hidden absolute inset-0 flex translate-y-[10%] flex-col ml-3 mt-2 transition-all duration-500">
                    <p className="mb-3 text-lg text-[#F1F1F1] transition-opacity duration-300">
                        {imageUrl.title}                                                </p>
                    <button className="w-[100px] rounded-full bg-[#E5362E] py-2 px-3.5 font-com text-sm capitalize text-[#F1F1F1] shadow ">
                        {t('readMore')}
                    </button>
                </div>
                </Link>
            </div>
        </div>
    
))}

</div>
</div>
</div>
    </>
  )
}
