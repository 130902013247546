import React, { useEffect, useRef, useState } from "react";
import SubNavbar from "../subnavbar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
// import { motion } from 'framer-motion'
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";

export default function AchieveCard() {
  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  const { t } = useTranslation();

  const [achieveSections, setAchieveSections] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`${baseUrl}/get_achievements.php`, {
        headers: {
          "Accept-Language": t("lang"), // Set the accepted language in the headers
        },
      })
      .then((response) => {
        console.log("API response data:", response.data); // Log the API response
        const sections = response.data; // Assuming the API response is the array of sections
        setAchieveSections(sections); // Set the complete sections array
      })
      .catch((error) => {
        console.error("Error fetching offers:", error);
      });
  }, []);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const achieveName = searchParams.get("title");
  console.log("Selected officer Name:", achieveName);

  const [achieveData, setAchieveData] = useState(null);
  useEffect(() => {
    if (achieveName) {
      // Fetch data for the selected manager using their name
      fetch(`${baseUrl}/get_achievements_info.php?title=${achieveName}`, {
        headers: {
          "Accept-Language": t("lang"),
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("API response data:", data);

          if (Array.isArray(data) && data.length > 0) {
            // Access the name property from the first object in the array
            setAchieveData(data[0]);
          } else {
            console.error("No manager data found.");
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [achieveName]);

  const [isOpen, setIsOpen] = useState(false);

  const [showFullscreenImage, setShowFullscreenImage] = useState(false);
  const [displayType, setDisplayType] = useState("cards"); // Default display type

  const handleImageClick = () => {
    setShowFullscreenImage(true);
  };

  const closeFullscreenImage = () => {
    setShowFullscreenImage(false);
  };

  const [openSections, setOpenSections] = useState({
    section1: false,
    section2: false,
    // Define open status for other sections
  });

  // const toggleDropdown = (sectionId) => {
  //   setOpenSections((prevState) => ({
  //     ...prevState,
  //     [sectionId]: !prevState[sectionId],
  //   }));
  // };

  const [activeTab, setActiveTab] = useState("content-1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // const handleTabClick = (target) => {
  //   setActiveTab(target === activeTab ? '' : target);
  // };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    // Function to handle clicks outside of the dropdown
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    }

    // Add event listener when the dropdown is open
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Remove the event listener when the dropdown is closed
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  if (!achieveData) {
    return <>Loading...</>;
  }

  return (
    <>
      <div className="">
        <div className="bg-gradient-to-r from-[#28313B] to-[#202020] min-h-screen pb-10">
          <div className="pt-[40px] xl:mx-[120px] px-[10px]">
            <SubNavbar />
          </div>
          <div className="xl:flex justify-between mt-5 mb-3  xl:mx-[120px] px-[10px] py-5">
            <div className=" text-[#E5362E]  lg:text-[24px] tracking-wider md:w-[500px] ">
              {achieveData.title}
            </div>
            <div className="flex text-[20px]  w-full justify-end mt-10 md:mt-0 ">
              <div className="h-[0px] ">
                <div className="relative z-30 ">
                  {/* Button */}
                  <button
                    className={`bg-[#E5362E] rounded-[20px] cursor-pointer text-[16px] lg:text-[20px]  text-[#f1f1f1] w-[210px]  md:w-[240px] lg:w-[400px]  py-1 lg:py-3`}
                    onClick={toggleDropdown}
                  >
                    <label htmlFor="num22" className="">
                      {achieveData.title}
                    </label>
                  </button>
                  {/* Dropdown Box */}
                  <div
                    ref={dropdownRef} // Reference to the dropdown div
                    className={`bg-[#E5362E] z-10 -mt-5 text-[12px]  rounded rounded-b-[20px] shadow-xl cursor-pointer bg-blur-md w-[210px] md:w-[240px] lg:w-[400px] text-[#f4f4f4]  h-auto p-[20px] ${
                      isDropdownOpen ? "block" : "hidden"
                    }`}
                  >
                    {achieveSections.map((project, index) => (
                      <ul key={index}>
                        <Link to={`/ourachievecard?title=${project.title}`}>
                          <li
                            className="hover:bg-red-400 text-[12px]  py-1 px-2"
                            onClick={() => {
                              closeDropdown(); // Close the dropdown when a list item is clicked
                            }}
                          >
                            {project.title}
                          </li>
                        </Link>
                      </ul>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col h-auto bg-gradient-to-r from-[#28313B] to-[#202020] px-[10px] xl:px-[120px] ">
            <div className="flex w-full mt-10 gap-3 carousel">
              {achieveData.projects.map((project, index) => (
                <div
                  className={`z-0 flex-grow lg:w-full w-[200px]  text-center max-w-sm lg:p-3 p-[8px]  rounded-2xl bg-[#E5362E] text-white cursor-pointer carousel-item mb-2 ${
                    activeTab === `content-${index + 1}` ? "" : ""
                  }`}
                  onClick={() => handleTabClick(`content-${index + 1}`)}
                  key={index}
                >
                  <button className="font-medium lg:w-full w-[210px] text-center lg:text-[18px]  text-[10px]">
                    {project.subtitle}
                  </button>
                </div>
              ))}
            </div>

            <div className="flex-grow relative h-auto">
              {achieveData.projects.map((project, index) => (
                <div
                  key={index}
                  className={`h-auto overflow-y-auto ${
                    activeTab === `content-${index + 1}` ? "block" : "hidden"
                  }`}
                >
                  <div className="p-4">
                    <div className=" lg:flex justify-between mt-20 pb-5">
                      <div className="flex justify-center ">
                        <div className="">
                          <img
                            src={project.heroImage}
                            alt=""
                            className="rounded-[50%] xl:h-[720px] xl:w-[720px] lg:h-[520px] lg:w-[520px] md:w-[350px] md:h-[350px] h-[250px] w-[250px]  z-20"
                          />
                        </div>
                      </div>
                      <div className="lg:w-[39%] mt-20 ">
                        <p className="text-[#E5362E] lg:text-[30px] text-[20px] font-semibold xl:w-[400px] ">
                          {project.subtitle}
                        </p>
                        <hr className="border-2 my-5" />
                        <p
                          className="text-[16px] xl:text-[18px] text-[#f1f1f1] text-justify leading-"
                          dangerouslySetInnerHTML={{
                            __html: project.description,
                          }}
                        >
                          {/* {project.description} */}
                        </p>
                      </div>
                    </div>
                    <div className="lg:flex justify-between ">
                      <div className="lg:w-[50%]  lg:h-[100%]">
                        <img
                          src={project.images[0]}
                          alt=""
                          className="w-full h-full object-full rounded-[20px]"
                        />
                      </div>
                      <div className="lg:w-[50%] mt-5 h-full lg:mt-0">
                        <div className="  w-full lg:pl-[20px]">
                          <img
                            src={project.images[1]}
                            alt=""
                            className="object-fill xl:h-[270px] lg:h-[200px] md:h-[300px] h-[200px] w-full h-full rounded-[10px] lg:rounded-[20px]"
                          />
                        </div>
                        <div className=" flex lg:h-full justify-between">
                          <div className="lg:w-[50%] w-[48%] lg:pl-5 pt-[20px]">
                            <img
                              src={project.images[2]}
                              alt=""
                              className="object-fill w-full lg:h-[180px] h-[150px] md:h-[160px] xl:h-[200px] rounded-[10px] lg:rounded-[20px]"
                            />
                          </div>
                          <div className="lg:w-[50%] w-[48%] lg:pl-5 pt-[20px]">
                            <img
                              src={project.images[3]}
                              alt=""
                              className="object-fill w-full lg:h-[180px] h-[150px] md:h-[160px] xl:h-[200px] rounded-[10px] lg:rounded-[20px]"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
