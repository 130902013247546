import React, { useEffect, useState, useRef } from 'react'
import SubNavbar from '../subnavbar'
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router';

export default function OfferCard() {

  const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD; 

  const { t } = useTranslation();

  // 

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const offerName = searchParams.get('title');
  console.log('Selected officer Name:', offerName);
  const [offerData, setOfferData] = useState(null);
  useEffect(() => {
    if (offerName) {
      // Fetch data for the selected manager using their name
      fetch(`${baseUrl}/get_offers.php?title=${offerName}`, {
        headers: {
          'Accept-Language': t('lang'),
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log('API response data:', data);

          if (Array.isArray(data) && data.length > 0) {
            // Access the name property from the first object in the array
            setOfferData(data[0]);
          } else {
            console.error('No manager data found.');
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [offerName]);


  const containerRef = useRef(null);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  const handleScrollButtonClick = (direction) => {
    const container = containerRef.current;
    const projectWidth = container.offsetWidth;

    if (direction === 'forward' && currentProjectIndex < offerData.projects.length - 1) {
      setCurrentProjectIndex(currentProjectIndex + 1);
    } else if (direction === 'back' && currentProjectIndex > 0) {
      setCurrentProjectIndex(currentProjectIndex - 1);
    }

    if (container) {
      container.scrollTo({
        left: currentProjectIndex * projectWidth,
        behavior: 'smooth',
      });
    }
  };
  const projectsData = [];


  if (!offerData) {
    return <>Loading...</>;
  }




  return (
    <>
      <div className="min-h-screen bg-gradient-to-r  from-[#28313B] to-[#202020] pb-20 ">
        <div className='pt-5 mx-5 xl:mx-[120px]'>
          <SubNavbar />
        </div>
        <div className="mx-5 xl:mx-[120px]">
          <p className=" text-[#E5362E] text-[17px] xl:text-[24px] font-medium tracking-widest my-3">{t('businessLines')}</p>
        </div>
        <div className="px-5 xl:px-[120px] block lg:flex w-full">
          <div className="lg:w-[47%] pb-5">
            <p className="text-[#E5362E] font-semibold text-[20px] xl:text-[36px] py-2">{offerData.title}</p>
            <div className="p-4 text-justify xl:text-[18px] h-[40vh] overflow-x-hidden lg:h-[50vh] xl:h-[50vh] lg:ml-2 text-[16px] text-[#f1f1f1]">
              <p className="" dangerouslySetInnerHTML={{__html:offerData.descriptionTwo}}></p>
            </div>
          </div>
          <div className="flex justify-center ">
            <div className="lg:ml-20 mt-7" >
              <img src={offerData.image2} alt="" className='rounded-[50%] border-[2px] h-[250px] w-[350px] lg:h-[400px] lg:w-[700px]  z-20' />
            </div>
          </div>
        </div>
        <div className="xl:h-[130px] lg:h-[130px] w-full bg-[#E5362E]  grid content-center justify-center " >
          <a href='#scroll'><p className="text-[24px] text-[#f1f1f1] font-bold uppercase">{t('Scroll')}</p></a>
        </div>

        <div className='flex  carousel xl:px-10 lg:px-5'>
          {offerData.projects.length > 0 &&
             <div className='flex' onClick={() => handleScrollButtonClick("back")}>
                <button  className="hidden  lg:block">
                <span className="invisible lg:visible material-icons material-symbols-outlined text-[#E5362E] lg:!text-[34px] cursor-pointer !font-extralight xl:mr-[10px] mt-5">
                  arrow_circle_left
    
                </span>
              </button>
              </div>
          }
          
          <div className="carousel flex w-full mt-20 p-2 lg:p-0" ref={containerRef} id='scroll'>
            {offerData.projects.map((exp, index) => (
              <div
                className={`carousel-item   ${index === currentProjectIndex ? 'active' : ''}`}
                key={index}
                style={{ display: 'inline-block', width: '100%'  }}
              >
                <div className="block lg:flex justify-center  carousel-items">
                  <div className="flex justify-center">
                    <img src={exp.projectImage} alt='' className="object-fill xl:h-[450px] rounded-2xl xl:w-[470px] lg:h-[400px] lg:w-[400px] w-[240px]" />
                  </div>
                  <div className="xl:w-[590px] ml-6 mt-4 lg:mt-0">
                    {exp && exp.projectName &&(
                    <div className="">
                      <p className="text-[#E5362E] lg:text-[24px] font-bold tracking-wider xl:py-2 lg:py-1">{t('nameOfProject')}</p>
                      <p className="text-[#f1f1f1] text-[14px] lg:text-[18px]">{exp.projectName}</p>
                    </div>)}

                    {exp && exp.location &&(

                    <div className="">
                      <p className="text-[#E5362E] lg:text-[24px] font-bold tracking-wider xl:py-2 lg:py-1">{t('location')}</p>
                      <p className="text-[#f1f1f1] text-[14px] lg:text-[18px]">{exp.location}</p>
                    </div>)}
                    {exp && exp.type &&(

                    <div className="">
                      <p className="text-[#E5362E] lg:text-[24px] font-bold tracking-wider xl:py-2 lg:py-1"> {t('typeOfProject')}</p>
                      <p className="text-[#f1f1f1] text-[14px] lg:text-[18px] text-justify" dangerouslySetInnerHTML={{__html:exp.type}}></p>
                    </div>)}
                    {exp && exp.management &&(

                    <div className="">
                      <p className="text-[#E5362E] lg:text-[24px] font-bold tracking-wider xl:py-2 lg:py-1"> {t('projectManagement')}</p>
                      <p className="text-[#f1f1f1] text-[14px] lg:text-[18px]"dangerouslySetInnerHTML={{__html:exp.management}}></p>
                    </div>)}
                    {exp && exp.fromDate && exp.toDate &&(
                    <div className="">
                      <p className="text-[#E5362E] lg:text-[24px] font-bold tracking-wider xl:py-2 lg:py-1"> {t('dateOfMission')}</p>
                      <p className="text-[#f1f1f1] text-[14px] lg:text-[18px]">{exp.fromDate} - {exp.toDate}</p>



                    </div>
                    )}
                  </div>
                </div>
              </div>
            ))}

          </div>
          {offerData.projects.length > 0 && 
          <div className='flex'  onClick={() => handleScrollButtonClick("forward")}>

              <button  className="hidden lg:block ">
              <span className="material-symbols-outlined lg:!text-[34px] text-[#E5362E] cursor-pointer !font-thin invisible lg:visible xl:ml-[10px] mt-5">
                arrow_circle_right
              </span>
            </button>
            </div>

          }
          
        </div>
      </div>
    </>
  )
}
