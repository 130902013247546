import { useEffect, useRef, useState } from "react";
import Navbar from "../navbar";
import Herosection from "../herosection";
import { useTranslation } from "react-i18next";
import axios from "axios";

export default function Home(props) {
  // const videoSources = [
  //   "/assets/main_banner1.jpg",
  //   "/assets/banner_img.png",

  // ];
  const { t } = useTranslation();

  const herosectionRef = useRef(null);

  const handleScrollNext = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const [heroImages, setHeroImages] = useState([]);
  const [randomHeroImageIndex, setRandomHeroImageIndex] = useState(0);
  const [newSections, setNewSections] = useState([]);





  const [isLoading, setIsLoading] = useState(true);

  const baseUrl =
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_BASE_URL_DEV
      : process.env.REACT_APP_BASE_URL_PROD;

  console.log(baseUrl);

  console.log(newSections.herovideo)



  useEffect(() => {
    async function fetchData() {
      try {
        const newsResponse = await axios.get(
          `${baseUrl}/get_homepage.php`,
          {
            headers: {
              "Accept-Language": t("lang"),
            },
          }
        );


        const data = newsResponse.data[0];
        setNewSections(newsResponse.data);

        if (data && data.heroImage && Array.isArray(data.heroImage) && data.heroImage.length > 0) {
          setHeroImages(data.heroImage);

          const randomIndex = Math.floor(Math.random() * data.heroImage.length);
          setRandomHeroImageIndex(randomIndex);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  return (
    <>
      <Navbar />

      <div className="overflow-x-hidden ">
        <div className="absolute w-full ">
          <img
            src={heroImages[randomHeroImageIndex]}
            alt=""
            className="h-[70vh] lg:h-screen w-full"
          />
        </div>
        {/* lg */}
        <div className="relative bg-[#000] bg-opacity-70 h-[70vh] lg:h-screen "></div>
        <div className="z-0 absolute w-full h-[70vh] lg:h-screen grid justify-center  content-center top-0">
          <div className=" w-[900px]   hidden lg:block pl-10 ">
            <div className="clip_video">
              {newSections.map((videoUrl, index) => (

                <video autoPlay muted loop className="!w-full">
                  <source
                    src={videoUrl.heroVideo}
                    type="video/mp4"
                    className="clip_video"
                  />
                </video>
              ))}
            </div>
            <svg width={"2%"} className="max-w-[100px]">
              <clipPath id="text-overlay" width="0%" height="80%">
                <path
                  d="M411.556 69.8452C429.456 84.0452 445.856 100.045 462.356 115.745C476.456 129.145 490.656 142.645 504.756 156.045C512.956 163.745 521.056 171.545 529.256 179.245C533.056 182.845 536.856 186.445 540.656 190.045C541.756 191.145 542.856 192.145 543.956 193.245L544.156 193.445C533.556 202.545 523.156 211.745 512.656 220.945C511.756 221.745 503.056 229.545 502.756 229.245C499.456 225.445 495.556 222.245 491.856 218.945C487.856 215.345 483.756 211.845 479.756 208.245C469.456 199.245 459.156 190.245 448.456 181.745C442.956 177.345 437.356 173.145 431.656 169.045C420.856 161.245 409.556 153.545 398.056 146.745C386.756 140.045 375.356 133.545 363.556 127.645C351.856 121.745 339.956 116.345 327.656 111.845C320.156 109.045 312.556 106.545 304.856 104.445C294.156 101.545 283.256 99.2452 272.256 97.9452C247.956 94.8452 222.856 95.8452 199.156 102.145C178.156 107.745 158.556 119.545 144.356 136.145C129.356 153.745 119.856 176.745 121.156 200.145C122.356 223.145 135.656 244.045 151.756 259.845C152.456 260.545 153.256 261.245 153.956 261.945C154.856 262.845 155.756 263.645 156.756 264.445C157.456 265.045 158.156 265.645 158.756 266.245C169.356 275.345 181.656 282.445 195.356 285.545C211.056 289.145 227.956 285.345 242.656 279.645C243.256 279.445 249.156 276.645 249.356 276.845C249.356 276.845 366.856 393.545 368.056 394.745C367.856 394.845 367.756 394.945 367.756 394.945C365.356 396.345 349.056 406.745 348.056 407.345C331.156 416.845 313.256 424.745 294.756 430.845C270.356 438.845 244.856 443.545 219.156 443.545C169.856 443.645 122.956 424.945 85.5561 393.245C50.6561 363.645 20.5561 325.745 7.95611 281.145C-5.74389 232.745 -1.24389 179.145 17.5561 132.645C34.0561 91.9452 64.5561 57.9452 101.956 35.2452C143.956 9.64522 193.256 -3.35478 242.456 0.745223C280.756 3.84522 318.156 14.9452 352.556 32.1452C370.656 41.2452 387.856 51.9452 404.056 64.0452C406.556 65.9452 409.156 67.8452 411.556 69.8452Z"
                  fill="#E5362E"
                />
                <path
                  d="M460.457 47.9454C458.757 49.1454 570.657 170.945 570.757 171.045C571.257 171.545 579.557 169.245 580.357 169.145C618.457 163.245 658.257 180.345 678.957 213.145C703.357 251.945 692.557 298.745 659.257 328.345C620.257 362.945 564.957 363.145 517.557 347.445C478.957 334.645 442.957 313.045 410.357 289.145C401.557 282.645 332.757 219.245 332.057 219.845C318.157 230.545 298.957 245.945 285.057 256.645C319.857 297.445 342.557 321.645 382.857 356.945C458.257 422.945 556.757 465.145 657.557 438.945C701.957 427.345 741.657 402.045 770.357 366.145C816.557 308.245 826.557 229.945 802.057 160.845C792.057 132.545 777.457 105.945 757.657 83.2453C734.357 56.3453 704.457 35.6454 671.257 22.8454C657.657 17.5454 643.557 13.5454 629.157 10.7454C587.857 2.84535 545.057 6.94535 506.057 23.0454C490.057 29.5454 474.757 38.0454 460.457 47.9454Z"
                  fill="#E5362E"
                />
              </clipPath>
            </svg>
          </div>

          {/* mobile */}
          <div className=" w-[290px]  block lg:hidden">
            <div className="clip_video1">
            {newSections.map((videoUrl, index) => (

              <video autoPlay muted loop>
                <source
                  src={videoUrl.heroVideo}
                  type="video/mp4"
                  className="clip_video"
                />
              </video>
                            ))}

            </div>
            <svg width={"2%"} className="max-w-[100px]">
              <clipPath id="text-overlay1" width="0%" height="100%">
                <path
                  id="title"
                  x="0"
                  y="0"
                  d="M146.474 25.3086C152.821 30.3432 158.635 36.016 164.485 41.5825C169.484 46.3334 174.519 51.1199 179.518 55.8708C182.426 58.6009 185.297 61.3664 188.205 64.0964C189.552 65.3728 190.899 66.6492 192.247 67.9256C192.637 68.3156 193.027 68.6701 193.417 69.0601L193.487 69.131C189.729 72.3575 186.042 75.6193 182.319 78.8812C182 79.1648 178.915 81.9303 178.809 81.824C177.639 80.4767 176.256 79.3421 174.945 78.1721C173.526 76.8957 172.073 75.6548 170.654 74.3784C167.003 71.1874 163.351 67.9965 159.557 64.9828C157.607 63.4228 155.622 61.9337 153.601 60.48C149.771 57.7145 145.765 54.9845 141.688 52.5735C137.681 50.198 133.639 47.8935 129.456 45.8016C125.307 43.7098 121.088 41.7952 116.727 40.1997C114.068 39.207 111.374 38.3206 108.644 37.576C104.85 36.5478 100.985 35.7324 97.0852 35.2715C88.4696 34.1724 79.5704 34.5269 71.1676 36.7606C63.722 38.7461 56.7728 42.9298 51.7382 48.8153C46.4199 55.0554 43.0517 63.21 43.5126 71.5065C43.9381 79.6612 48.6536 87.0713 54.3619 92.6732C54.61 92.9214 54.8937 93.1696 55.1419 93.4178C55.461 93.7369 55.7801 94.0205 56.1346 94.3041C56.3828 94.5169 56.631 94.7296 56.8437 94.9423C60.6019 98.1687 64.9629 100.686 69.8203 101.785C75.3867 103.062 81.3786 101.714 86.5905 99.6933C86.8032 99.6224 88.8951 98.6296 88.966 98.7006C88.966 98.7006 130.626 140.077 131.051 140.502C130.98 140.538 130.945 140.573 130.945 140.573C130.094 141.069 124.315 144.757 123.96 144.969C117.968 148.338 111.622 151.139 105.063 153.301C96.4116 156.138 87.3705 157.804 78.2586 157.804C60.7792 157.84 44.1508 151.21 30.8906 139.97C18.5168 129.476 7.84482 116.038 3.37748 100.225C-1.47986 83.0649 0.115618 64.061 6.78117 47.5744C12.6313 33.1442 23.445 21.0894 36.7052 13.0411C51.5964 3.96464 69.0757 -0.644516 86.5196 0.809141C100.099 1.90825 113.359 5.84376 125.556 11.942C131.973 15.1684 138.071 18.9621 143.815 23.2522C144.701 23.9258 145.623 24.5995 146.474 25.3086Z"
                  fill="#E5362E"
                />
                <path
                  d="M163.811 17.5439C163.208 17.9694 202.883 61.1537 202.918 61.1891C203.095 61.3664 206.038 60.5509 206.322 60.5155C219.83 58.4236 233.941 64.4864 241.281 76.1157C249.932 89.8723 246.102 106.465 234.296 116.96C220.468 129.227 200.862 129.298 184.056 123.732C170.37 119.194 157.607 111.535 146.048 103.062C142.928 100.757 118.535 78.2785 118.287 78.4912C113.359 82.2849 106.551 87.7449 101.623 91.5386C113.961 106.004 122.01 114.584 136.298 127.1C163.031 150.5 197.954 165.462 233.693 156.173C249.435 152.06 263.511 143.09 273.686 130.362C290.067 109.833 293.612 82.0721 284.926 57.5727C281.38 47.5389 276.204 38.1079 269.184 30.0596C260.923 20.5222 250.322 13.183 238.55 8.64471C233.729 6.76559 228.729 5.34739 223.624 4.35465C208.981 1.5537 193.806 3.00736 179.979 8.71562C174.306 11.0202 168.881 14.0339 163.811 17.5439Z"
                  fill="#E5362E"
                />
              </clipPath>
            </svg>
          </div>
        </div>
        <div className="absolute w-full h-[70vh] lg:h-screen grid content-end top-0 ">
          <div className="text-[#F1F1F1] xl:mx-[120px] mx-5 pb-2">
            <hr className=" w-full text-[#F1F1F1] z-10 lg:hidden xl:block" />
            <div className="flex justify-between">
              <div className="">
                <p className="md:text-[30px] text-[26px] pt-10 text-[#fff]">
                  01
                  <span className="md:text-[20px] text-[16px]  text-[#E8372C]">
                    /06
                  </span>
                </p>
              </div>
              <div className="grid justify-items-center mt-[20px]">
                <span className="flex justify-center rotate-12 mb-[10px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="48"
                    viewBox="0 -960 960 960"
                    width="48"
                    className="lg:ml-2"
                  >
                    <path
                      d="M123-584q-4-2-4-6.5t2-8.5q62-86 157-133t203-47q108 0 203.5 46T843-601q3 5 2.5 8t-3.5 6q-3 3-7.5 3t-8.5-5q-59-82-150.5-126T481-759q-103 0-193 44.5T138-589q-4 5-7.5 6t-7.5-1ZM600-81q-103-26-169.5-103T364-371q0-47 34.5-79t82.5-32q48 0 82.5 32t34.5 79q0 38 29.5 64t68.5 26q38 0 66.5-26t28.5-64q0-123-91.5-206T481-660q-127 0-218.5 83T171-371q0 24 5.5 62.5T200-221q2 5 0 7.5t-5 4.5q-4 2-8.5 1t-6.5-6q-13-38-20.5-77.5T152-371q0-129 98-220.5T481-683q136 0 233.5 90T812-371q0 46-34 78t-82 32q-49 0-84-32t-35-78q0-39-28.5-65T481-462q-39 0-68 26t-29 65q0 104 63 173.5T604-100q6 2 7.5 5t.5 7q-1 5-4 7t-8 0ZM247-801q-5 2-7.5.5T235-805q-2-2-2-6t3-6q57-31 119.5-47T481-880q65 0 127.5 16T728-819q5 2 5.5 6t-1.5 7q-2 3-5.5 5t-8.5 0q-55-27-115-42.5T481-859q-62 0-121 14.5T247-801ZM381-92q-58-60-90.5-126T258-371q0-89 65.5-150T481-582q92 0 158.5 61T706-371q0 5-2.5 7.5T696-361q-5 0-8-2.5t-3-7.5q0-81-60.5-136T481-562q-83 0-142.5 55T279-371q0 85 29.5 145T396-106q4 4 3.5 7.5T396-92q-2 2-6.5 3.5T381-92Zm306-73q-88 0-152.5-58.5T470-371q0-5 2.5-8t7.5-3q5 0 7.5 3t2.5 8q0 81 59.5 133.5T687-185q8 0 19-1t24-3q5-1 8 1.5t4 5.5q1 4-.5 7t-6.5 4q-18 5-31.5 5.5t-16.5.5Z"
                      stroke="#E8372C"
                      strokeWidth="30"
                    />
                  </svg>
                </span>

                <a href="#herosection">
                  <button
                    className="hidden ml-1 px-2 rounded-md lg:block text-[20px] mt-12 z-50 tracking-widest  font-normal	text-[#f1f1f1]"
                    onClick={() => handleScrollNext(herosectionRef)}
                    dangerouslySetInnerHTML={{ __html: t("scroll") }}
                  ></button>
                </a>
              </div>

              <div className="invisible">
                <p className="">Dummy</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Herosection prop1="Value 1" prop2="Value 2" ref={herosectionRef} />
    </>
  );
}
