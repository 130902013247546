import React, { useEffect, useState } from 'react'
import SubNavbar from '../../subnavbar';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export default function NewsCard() {

  const [showFullscreenImage, setShowFullscreenImage] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null); // Add this state


  const handleImageClick = (index) => {
    setSelectedImageIndex(index); // Set the selected image index

    setShowFullscreenImage(true);
  };

  const closeFullscreenImage = () => {
    setSelectedImageIndex(null); // Set the selected image index

    setShowFullscreenImage(false);
  };
  const { t } = useTranslation();

  const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD; 



  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const newsName = searchParams.get('title');
  console.log('Selected officer Name:', newsName);

  const [newsData, setNewsData] = useState(null);
  useEffect(() => {
    if (newsName) {
      // Fetch data for the selected manager using their name
      fetch(`${baseUrl}/get_newsByTitle.php?title=${newsName}`, {
        headers: {
          'Accept-Language': t('lang'),
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log('API response data:', data);

          if (Array.isArray(data) && data.length > 0) {
            // Access the name property from the first object in the array
            setNewsData(data[0]);
          } else {
            console.error('No manager data found.');
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [newsName]);

  if (!newsData) {
    return <>Loading...</>;
  }


  return (
    <>
      <div className="">
        <div className=" ">
          <div className="  h-full w-full">
            <img src={newsData.image} alt="" className="h-[80vh] lg:h-screen w-full" />

          </div>
          <div className="absolute inset-0 bg-gradient-to-b from-black via-transparent to-black h-[80vh] lg:h-screen"></div>
          <div className="absolute top-0 w-full h-[80vh] lg:h-screen">
            <div className="z-10 absolute w-full ">
              <div className="pt-[40px] px-[20px] xl:px-[120px]"  >
                <div className="">
                  <SubNavbar />

                  {/* start */}
                </div>
              </div>
            </div>
            <div className="z-0 absolute w-full h-full grid justify-center  content-center top-0">
              <div className="grid justify-center content-center">
                <p className="md:text-[64px] text-[35px]  text-[#F1F1F1] font-extrabold text-center xl:w-[800px]">
                  {/* {selectedContent.title} */}{newsData.title}
                </p>
              </div>

            </div>
            <div className="absolute  w-full h-full grid justify-center  content-end top-0 z-0 ">
              <a href="#content">
                <span className="material-symbols-outlined text-[#F1F1F1] mb-10 text-[36px] cursor-pointer">
                  arrow_circle_down
                </span>
              </a>
            </div>

          </div>
        </div>
        <div className="bg-gradient-to-r from-[#28313B] to-[#202020] xl:px-[120px]  px-[20px]" id='content'>
          <div className=" xl:py-[40px]  text-justify">
            <div className="w-full ">
              <p className="text-[#E5362E] text-[17px] xl:text-[24px] font-bold tracking-wider">{t('declaration')}</p>
              <p className="text-[16px] xl:text-[18px] font-normal text-[#F1F1F1] leading-9" dangerouslySetInnerHTML={{__html: newsData.description}}></p>
            </div>
          </div>
          <div className="pb-10">
            <p className="text-[#E5362E] text-[17px] xl:text-[24px] font-medium tracking-wider">{t('projectGallery')}</p>
            <div className="grid md:grid-cols-2 md:px-[80px] lg:grid-cols-4 gap-2 py-5">
              {newsData.gallery.map((imageUrl, index) => (
                <div key={index} className="flex justify-center items-center rounded ">
                  <div className="cursor-pointer " onClick={() => handleImageClick(index)}>
                    <img src={imageUrl} alt="" className="cursor-pointer w-[250px] lg:w-[300px] lg:h-[240px] rounded-[20px]" />
                  </div>
                  {showFullscreenImage && index === selectedImageIndex && (
                    <div
                      className="fixed top-0 left-0 w-full h-full flex justify-center rounded items-center bg-black bg-opacity-90"
                      onClick={closeFullscreenImage}
                    >
                      <img src={imageUrl} alt="" className="w-[800px] h-[600px] rounded" />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>
    </>
  )
}
