import React, { useEffect, useRef, useState } from "react";
import SubNavbar from "../../subnavbar";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

import { useTranslation } from "react-i18next";
import OfferAnimation from "../../offer-animation";

const SecurityRisk = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD;


  const [offerSections, setOfferSections] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`${baseUrl}/get_offers.php`, {
        headers: {
          "Accept-Language": t('lang'), // Set the accepted language in the headers
        },
      })
      .then((response) => {
        console.log("API response data:", response.data); // Log the API response
        const sections = response.data; // Assuming the API response is the array of sections
        setOfferSections(sections); // Set the complete sections array

      })
      .catch((error) => {
        console.error("Error fetching offers:", error);
      });
  }, []);


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  
  const offerName = searchParams.get('title');
  console.log('Selected officer Name:', offerName);
  const [offerData, setOfferData] = useState(null);
  useEffect(() => {
    if (offerName) {
      // Fetch data for the selected manager using their name
      fetch(`${baseUrl}/get_offers.php?title=${offerName}`, {
        headers: {
          'Accept-Language': t('lang'),
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          console.log('API response data:', data);

          if (Array.isArray(data) && data.length > 0) {
            // Access the name property from the first object in the array
            setOfferData(data[0]);
            const sectionId = `section${offerName}`;
            const sectionElement = document.querySelector(`[id="${sectionId}"]`);

            if (sectionElement) {
              sectionElement.scrollIntoView({ behavior: "smooth" });
            }

          } else {
            console.error('No manager data found.');
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [offerName]);



// smothh scroll
  const scrollToSection = (sectionId) => {
    const sectionElement = document.querySelector(`[data-custom-id="section${sectionId}"]`);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
    closeDropdown(); 

  };

  


 
  useEffect(() => {
    if (offerData && offerData.title) {
      // const sectionId = `section-${offerData.title}`;
      const sectionElement = document.querySelector(`[id="section${offerData.title}"]`);

  
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [offerData]);
 
    
  // 
   


  const outerDivRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  useEffect(() => {
    const updateDivHeight = () => {
      if (outerDivRef.current) {
        setDivHeight(outerDivRef.current.offsetHeight);
      }
    };

    // Initial measurement
    updateDivHeight();

    window.addEventListener('resize', updateDivHeight);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);

    return () => {
      window.removeEventListener('resize', updateDivHeight);
    };
  }, []);


  // dropdown
  const [openSections, setOpenSections] = useState({ dropdown: false });
  const dropdownRef = useRef(null);

  

  const toggleDropdown = () => {
    setOpenSections({ dropdown: !openSections.dropdown });
  };

  const closeDropdown = () => {
    setOpenSections({ dropdown: false });
  };

  function handleClickOutside(event) {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  }

  // Add event listener when the dropdown is open
  useEffect(() => {
    if (openSections.dropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openSections.dropdown]);



  if (!offerData || offerSections.length === 0) {
    return null; 
  }
  return (
    <>
      <div ref={outerDivRef}
        className="min-h-screen bg-gradient-to-r from-[#28313B] to-[#202020] h-auto pb-20"
        htmlfor="num22"
      >
        <div className="hidden lg:flex justify-center absolute left-0 right-0 top-0 z-20 invisible lg:visible">
          <OfferAnimation divHeight={divHeight} />
        </div>
        <div className="pt-5 mx-5 xl:mx-[120px]" >
          <SubNavbar />
        </div>
        {/* Display content here */}
        <div className="xl:px-[120px] z-20 px-5 relative"></div>
        <div className="block md:flex justify-between mt-5 mb-3 mx-5 xl:mx-[120px] py-5">
          <div className=" text-[#E5362E] text-[18px] xl:text-[24px] tracking-wider">
            {t("businessLines")}
          </div>
          <div className="flex text-[20px] mt-5 md:mt-0 ">
            <div className="relative text-[#f1f1f1]">
              <button
                onClick={toggleDropdown}

                className="p-3 w-[265px] bg-[#E5362E] rounded-[10px] shadow cursor-pointer capitalize"
              >
                {offerData.title}
              </button>

              {openSections.dropdown && (
        <div
          ref={dropdownRef}
          className="absolute top-[100%] left-0 z-30 text-[16px] text-left p-2 -mt-4 bg-[#E5362E] rounded-b-[20px] shadow-xl"
        >
          {offerSections.map((section, index) => (
            <div
              key={`dropdownSection${index}`}
              onClick={() => {
                toggleDropdown();
                scrollToSection(section.id); // Scroll to the section ID
              }}
              className="my-2 px-4 py-2 hover:bg-red-400 cursor-pointer bg-[#E5362E] rounded-[5px] text-[16px]"
            >
              {section.title}
            </div>
          ))}
        </div>
      )}
            </div>
          </div>
        </div>

        {/* 1 */}




        {offerSections.map((section, index) => (


          <div  key={`section-${index}`} id={`section${section.title}`} data-custom-id={`section${section.id}`}>
            {parseInt(section.id) % 2 !== 0 ? (

              <div >
                <div className="flex gap-2 ml-2 ">
                  <p className="text-[36px] lg:text-[96px] font-semibold text-[#63696f] mt-4  mr-4">
                    {section.id}
                  </p>


                  <p className="text-[20px] lg:text-[36px] font-semibold text-[#f1f1f1] mt-6 lg:mt-[70px] z-20">
                    {section.title}</p>
                </div>
                <div className="block md:flex justify-between mt-1">
                  <div className="w-full h-auto lg:w-[50%] pr-[10px]">
                    <div className="bg-[#E5362E] min-h-[420px] grid content-center px-4 lg:pl-[120px] lg:pr-10">
                      <div className=" text-[#f1f1f1] text-justify py-2">
                        <p className="lg:text-[18px] text-[14px]" dangerouslySetInnerHTML={{ __html: section.description }}>
                          {/* {section.description} */}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" w-full px-4 lg:mt-0 xl:pr-[120px] lg:w-[50%] pl-[10px]">
                    <div className="lg:block hidden rounded-[10px] h-[50vh] lg:h-[430px] md:h-[380px] group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                      <div className=" lg:h-[430px] md:h-[380px]">
                        <img
                          className="h-[50vh] md:h-full w-full object-cover transition-transform duration-500 group-hover:scale-110"
                          src={section.image}
                          alt=""
                        />
                      </div>
                      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                      <div className="absolute flex justify-center inset-0 translate-y-[90%] ml-3 mt-40 transition-all duration-500 group-hover:translate-y-0">
                        <div className="">
                          <p className="mb-3 text-[24px] text-[#F1F1F1] opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                            {section.subtitle} 
                          </p>
                          <div className="flex justify-center">
                            <Link to={`/ouroffercard?title=${section.title}`}>
                          
                              {" "}
                              <button className="text-[20px] rounded-full bg-[#E5362E] py-2 px-3.5 capitalize text-[#F1F1F1] shadow opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                                {t("readMore")}
                              </button>{" "}
                            </Link>
                          </div>
                        </div>
                      </div>


                    </div>

                    {/* mobile  */}

                    <div className="mt-5 md:mt-0 lg:hidden rounded-[10px] h-[50vh] lg:h-[430px] md:h-[380px] group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                      <div className="relative lg:h-[430px] md:h-[380px]">
                        <img
                          className="h-[50vh] md:h-full w-full object-cover transition-transform duration-500 group-hover:scale-110"
                          src={section.image}
                          alt=""
                        />
                        <div className="absolute inset-0 bg-gradient-to-b from-black to-black via-black/60 "></div>
                        <div className="absolute inset-0 flex flex-col justify-center items-center text-center">
                          <div className="text-[24px] text-[#F1F1F1] duration-300">
                            {section.subtitle}
                          </div>
                          <div className="mt-3">
                            <Link to={`/ouroffercard?title=${section.title}`}>
                              <button className="text-[20px] rounded-full bg-[#E5362E] py-2 px-3.5 capitalize text-[#F1F1F1] shadow transition-opacity duration-300">
                                {t("readMore")}
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>



                    </div>
                  </div>
                </div>
              </div>


            ) : (


              <div className="xl:mt-[100px]" id="section2">
                <div className="flex mx-5 lg:mx-[120px] justify-end ">
                  <p className="text-[36px] lg:text-[96px] font-semibold text-[#63696f] mt-4 mr-4 z-20">
                    {section.id}</p>
                  <p className="text-[20px] lg:text-[36px] font-semibold text-[#f1f1f1] mt-6 lg:mt-[70px] z-20">
                    {section.title}
                  </p>
                </div>
                <div className="block md:flex justify-between">
                  <div className="w-full lg:h-[430px] md:h-[380px] px-4 mt-2 lg:mt-0 xl:pl-[120px] lg:w-[50%] pr-[10px]">
                    <div className="hidden lg:block rounded-[10px] h-[50vh] lg:h-[430px] md:h-[380px] group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                      <div className=" lg:h-[430px] md:h-[380px]">
                        <img
                          className="h-[50vh] md:h-full w-full object-cover transition-transform duration-500 group-hover:scale-110"
                          src={section.image}
                          alt=""
                        />
                      </div>
                      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>
                      <div className="absolute flex justify-center inset-0 translate-y-[90%] ml-3 mt-40 transition-all duration-500 group-hover:translate-y-0">
                        <div className="">
                          <p className="mb-3 text-[24px] text-[#F1F1F1] opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                            {section.subtitle}                           </p>
                          <div className="flex justify-center">
                            <Link to={`/ouroffercard?title=${section.title}`}>
                              {" "}
                              <button className="text-[20px] rounded-full bg-[#E5362E] py-2 px-3.5 capitalize text-[#F1F1F1] shadow opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                                {t("readMore")}
                              </button>{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>




                    {/* mobile  */}
                    <div key={`section-${index}`} id={`section${section.title}`} data-custom-id={`section${section.id}`} className="lg:hidden rounded-[10px] h-[50vh] lg:h-[430px] md:h-[380px] group relative cursor-pointer items-center justify-center overflow-hidden transition-shadow hover:shadow-xl hover:shadow-black/30">
                      <div className="relative lg:h-[430px] md:h-[380px]">
                        <img
                          className="h-[50vh] md:h-full w-full object-cover transition-transform duration-500 group-hover:scale-110"
                          src={section.image}
                          alt=""
                        />
                        <div className="absolute inset-0 bg-gradient-to-b from-black to-black via-black/60 "></div>
                        <div className="absolute inset-0 flex flex-col justify-center items-center text-center">
                          <p className="mb-1 text-[24px] text-[#F1F1F1] duration-300">
                            {section.subtitle}
                          </p>
                          <div className="mt-3">
                            <Link to={`/ouroffercard?title=${section.title}`}>
                              <button className="text-[20px] rounded-full bg-[#E5362E] py-2 px-3.5 capitalize text-[#F1F1F1] shadow transition-opacity duration-300">
                                {t("readMore")}
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full h-auto lg:w-[50%] lg:h-[430px] pl-[10px] mt-2 lg:mt-0">
                    <div className="bg-[#E5362E] min-h-[420px] grid content-center px-4 lg:pr-[120px] lg:pl-10">
                      <div className=" text-[#f1f1f1] text-justify py-2">
                        <p className="lg:text-[18px] text-[14px]" dangerouslySetInnerHTML={{ __html: section.description }}>
                          {/* {section.description} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            )}


          </div>

        ))}









        {/* 3 */}




        {/* 4 */}


        {/* 5 */}


        {/* 6 */}



        {/* 7 */}


        {/* 8 */}



        {/* 9 */}
      </div>

    </>
  );
});

export default SecurityRisk;