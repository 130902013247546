import React, { useEffect, useState } from "react";
import SubNavbar from "../subnavbar";
import { useTranslation } from "react-i18next";
import axios from "axios";

export default function Business() {
  const { t } = useTranslation();
  const baseUrl = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL_PROD; 


  const [businessSections, setBusinessSections] = useState([]);

  console.log("hbdhsbhdbshd", businessSections)

  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`${baseUrl}/get_business.php`, {
        headers: {
          "Accept-Language": t('lang'), // Set the accepted language in the headers
        },
      })
      .then((response) => {
        console.log("API response data:", response.data); // Log the API response
        const sections = response.data; // Assuming the API response is the array of sections
        setBusinessSections(sections); // Set the complete sections array


      })
      .catch((error) => {
        console.error("Error fetching offers:", error);
      });
  }, []);


  return (
    <>
      <div>
        <div>
          <div className="min-h-screen bg-gradient-to-r from-[#28313B] to-[#202020] pb-20">
            <div className="relative z-50 mx-5 md:mx-10 lg:mx-7 xl:mx-[120px] ">
              <SubNavbar />
            </div>

            <div className="relative  xl:px-[85px] pt-[30px] ">
              {businessSections.map((section, index) => (
                <div>
                  <p className="lg:text-[20px] text-[18px] px-5 md:px-10 font-bold text-[#E8372C] tracking-wider">
                  {t("business")} 
                  </p>
                  {/* <p className='pt-2 lg:pt-10 text-justify xl:leading-9' dangerouslySetInnerHTML={{__html:section.description}}></p> */}
                  
                  <p className="  text-[#F1F1F1] lg:text-[18px] text-[16px] mt-[20px] leading-[35px] xl:w-[80vw] mx-5 md:mx-10 text-justify md:w-[85vw]  xl:leading-9" dangerouslySetInnerHTML={{__html:section.description}}></p>
                  <div className="grid grid-cols-1  md:grid-cols-2 gap-5 mt-10 justify-between">

                    <ul className="list-disc xl:leading-9 mx-10  font-extralight leading-[35px]  md:pl-16  text-[#F1F1F1] xl:text-[18px]  text-[16px] ">
                      {section.businessPoints.map((point, pointIndex) => (
                        <li>{point}</li>
                      ))}
                    </ul>

                    <div className="lg:w-[80%]">
                      <img
                        className="h-[250px] md:h-[300px] w-full px-5"
                        src={section.image}
                        alt=""
                      ></img>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
